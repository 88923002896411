/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateTransactionFeesDialogComponent } from '../update-transaction-fees-dialog/update-transaction-fees-dialog.component';
import { AddTransactionFeesDialogComponent } from '../add-transaction-fees-dialog/add-transaction-fees-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { RemoveTransactionFeesDialogComponent } from '../remove-transaction-fees-dialog/remove-transaction-fees-dialog.component';

@Component({
  selector: 'app-transaction-fees',
  templateUrl: './transaction-fees.component.html',
  styleUrls: ['./transaction-fees.component.scss']
})
export class TransactionFeesComponent implements OnInit {
  dataSource = [] as any;
  policyId: number;
  accountId: number;

  paginationConfig = {
    CurrentPage: 1,
    ItemsPerPage: 5,
    TotalItems: 0,
    Offset: 0
  };

  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private service: RestServicesService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
      this.accountId = params.accountId;
      if (this.accountId === undefined) {
        this.GetTransactionFees();
      } else {
        this.GetAccountControls();
      }
    });
  }

  pageChanged(page: number) {
    this.paginationConfig.CurrentPage = page;
    this.paginationConfig.Offset = (this.paginationConfig.CurrentPage - 1) * this.paginationConfig.ItemsPerPage;
    this.GetTransactionFees();
  }

  GetTransactionFees() {
    this.spinner.show();

    this.service.getTransactionFee(this.policyId, this.paginationConfig).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.paginationConfig.TotalItems = resp.body.metadata.totalRecord;
        this.dataSource = resp.body.data;
      }
    });
  }
  GetAccountControls() {
    this.spinner.show();

    this.service.getAccountControls(this.accountId).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.dataSource = resp.body.data;
      }
    });
  }

  addTransactionFeesDialog(): void {
    const dialogRef = this.dialog.open(AddTransactionFeesDialogComponent, {
      width: '50%',
      data: this.dataSource
    });
    dialogRef.afterClosed().subscribe(() => {
      this.GetTransactionFees();
    });
  }

  updateTransactionFeesDialog(data: any, index: number): void {
    const dialogRef = this.dialog.open(UpdateTransactionFeesDialogComponent, {
      width: '50%',
      data: { source: Object.assign({}, data), index }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.GetTransactionFees();
    });
  }

  removeTransactionFeesDialog(data: any, index: number): void {
    const dialogRef = this.dialog.open(RemoveTransactionFeesDialogComponent, {
      width: '50%',
      data: { source: Object.assign({}, data), index }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.GetTransactionFees();
    });
  }
}
