/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { errorMessage } from '../app-dto/error.dto';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TimeoutDialogComponent } from '../shared/shared-component/timeout-dialog/timeout-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { CustomAPIErrorResponse } from '../service-interface/interface-list';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomSnakbarComponent } from '../shared/shared-component/custom-snakbar/custom-snakbar.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isLogOutPopupCalled = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private auth: AuthService,
    private spinner:NgxSpinnerService,
    private customeSnakbar:CustomSnakbarComponent
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(retry(0), catchError((error: HttpErrorResponse) => {

      // closing spinner & dialog if any when error occour
      this.spinner.hide();

      if(!request.url.endsWith('validateotp')){
        this.dialog.closeAll();
      }
      if (error.error instanceof ErrorEvent) {
        this.handleClientError(error);
      } else {
        this.handleServerError(error, request);
      }
      return throwError(()=>error);
    }));
  }

  handleClientError(error) {
    this.customeSnakbar.openCustomSnakbar(`${error.error.message}`, '', 'error');
  }

  handleServerError(error, request) {
    switch (error.status) {
      case 504:
        this.showSnackbarError(errorMessage.error504);
        break;
      case 404:
        this.showSnackbarError(errorMessage.error404);
        break;
      case 500:
        this.showSnackbarError(errorMessage.error500);
        break;
      case 400:
        this.handleBadRequestError(error);
        break;
      case 406:
        this.customErrorHandler(error.error);
        break;
      case 403:
        this.handleForbiddenError();
        break;
      default:
        this.handleOtherErrors(error, request);
        break;
    }
  }


  handleBadRequestError(error) {
    if (error && error.error) {
      const [errCode, errMsg] = error.error.split(':', 2);
      this.customeSnakbar.openCustomSnakbar(errMsg, errCode, 'error');
    } else {
      this.showSnackbarError(errorMessage.error400);
    }
  }

  handleForbiddenError() {
    if (!this.isLogOutPopupCalled) {
      this.isLogOutPopupCalled = true;
      this.onLogout();
    }
  }

  handleOtherErrors(error, request) {
    if (error.error && error.error.detail) {
      this.customeSnakbar.openCustomSnakbar(error.error.detail, '', 'error');
    } else {
      const errorMessage = (request.method === 'PUT' || request.method === 'POST' || request.method === 'DELETE') ? 'Invalid Data' : 'Data Not Found';
      this.customeSnakbar.openCustomSnakbar(errorMessage, '', 'error');
    }
  }

  showSnackbarError(message) {
    this.customeSnakbar.openCustomSnakbar(message, '', 'error');
  }

  notifylogout() {
    const msg = 'You are being timed out due to inactivity. Please relogin again to stay signed in.';
    const dialogRef = this.dialog.open(TimeoutDialogComponent, {
      data: { text: msg, title: 'Session Timeout' }
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (!confirmed) {
        this.dialog.closeAll();
        this.isLogOutPopupCalled = false;
      }
    });
  }
  onLogout() {
    this.dialog.closeAll();
    this.auth.logout();
    this.router.navigate(['/login']);
    this.notifylogout();
  }



  customErrorHandler(error: CustomAPIErrorResponse): void {
    const errorMessage = error?.errorMessage;
    const errorCode = error?.errorCode;
    if (errorMessage.indexOf(':') <= 0) {
      this.customeSnakbar.openCustomSnakbar(errorMessage, errorCode, 'error');
    } else {
      try {
        let errCode = errorMessage.substring(0, errorMessage.indexOf(':'));
        let errMsg = errorMessage.substring(errorMessage.indexOf(':') + 1, errorMessage.length);
        errCode = errCode.trim();
        errMsg = errMsg.trim();
        this.customeSnakbar.openCustomSnakbar(errMsg, errCode, 'error');
      } catch {
        this.customeSnakbar.openCustomSnakbar('Something went wrong', '', 'error');
      }
    }
  }
}
