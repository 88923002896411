<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->
<mat-card class="mainCard">
<mat-card-title class="sectionTitle">
  <div class="flex flex-row justify-between items-center">
    <span class="mat-card-title">Replace Card</span>
  <button class="closeIcon" (click)="cancelClick()">
    <mat-icon>close</mat-icon>
  </button>
  </div>
</mat-card-title>

<mat-card-content>
  <form [formGroup]="replaceCardForm" (ngSubmit)="onReplaceCardFormSubmit()">
    <h6 class="text-left mt-3">(*A one-time $5 fee will apply)</h6>
    <div class="flex flex-row justify-between items-start">
      <h6 class="text-blue m-b-10">Shipping Address</h6>
      <div class="">
        <mat-slide-toggle class="example-margin" [(ngModel)]="isSameAsHomeAddress" [ngModelOptions]="{standalone: true}"
          [disabled]="false" (change)="addressToggleChange()"></mat-slide-toggle>
        &nbsp; &nbsp;
        <Label>Same as My Address</Label>
      </div>
    </div>
    <div class="flex flex-row justify-start items-center flex-wrap">
      <div class="box-padding mandatory w-[33.33%]">
        <mat-form-field>
          <mat-label>Address Line 1</mat-label>
          <input type="text" [maxLength]="50" matInput formControlName="address1" />
        </mat-form-field>
        <mat-error *ngIf="submitted && replaceCardForm.hasError('required',['address1'])" class="invalid-feedback">
          Address Line 1 is required
        </mat-error>
        <mat-error *ngIf="replaceCardForm.hasError('alphanumeric',['address1'])">
          Please provide valid input.
        </mat-error>
      </div>
      <div class="box-padding w-[33.33%]">
        <mat-form-field>
          <mat-label>Address Line 2</mat-label>
          <input type="text" [maxLength]="50" matInput formControlName="address2" />
        </mat-form-field>
        <mat-error *ngIf="replaceCardForm.hasError('alphanumeric',['address2'])">
          Please provide valid input.
        </mat-error>
      </div>

      <div class="box-padding w-[33.33%]">
        <mat-form-field>
          <mat-label>Address Line 3</mat-label>
          <input type="text" [maxLength]="50" matInput formControlName="address3" />
        </mat-form-field>
        <mat-error *ngIf="replaceCardForm.hasError('alphanumeric',['address3'])">
          Please provide valid input.
        </mat-error>
      </div>
      
    </div>
    <div class="flex flex-row justify-start items-center flex-wrap">
      <div  class="box-padding mandatory w-[33.33%]">
        <mat-form-field>
          <mat-label>City</mat-label>
          <input type="text" [maxLength]="50" matInput formControlName="city" />
        </mat-form-field>
        <mat-error *ngIf="submitted && replaceCardForm.hasError('required',['city'])" class="invalid-feedback">
          City is required
        </mat-error>
        <mat-error *ngIf="replaceCardForm.hasError('alphanumeric',['city'])">
          Please provide valid input.
        </mat-error>
      </div>
      <div class="box-padding mandatory w-[33.33%]">
        <mat-form-field>
          <mat-label>State</mat-label>
          <input type="text" [maxLength]="2" matInput formControlName="state" />
        </mat-form-field>
        <mat-error *ngIf="submitted && replaceCardForm.hasError('required',['state'])" class="invalid-feedback">
          State is required
        </mat-error>
        <mat-error *ngIf="replaceCardForm.hasError('alphanumeric',['state'])">
          Please provide valid input.
        </mat-error>
      </div>
      <div class="box-padding mandatory w-[33.33%]">
        <mat-form-field>
          <mat-label>Zip</mat-label>
          <input type="text" [maxLength]="10" matInput formControlName="zip" [ZipMask]="true" />
        </mat-form-field>
        <mat-error *ngIf="submitted && replaceCardForm.hasError('required',['zip'])" class="invalid-feedback">
          Zip is required
        </mat-error>
        <mat-error *ngIf="replaceCardForm.hasError('customZip',['zip'])">
          Please provide valid zip.
        </mat-error>
      </div>
    </div>

    <div class="button-row flex flex-row justify-start gap-2">
      <button mat-raised-button color="primary" color="primary">Replace</button>
      <button mat-raised-button color="default" (click)="cancelClick()" type="button">Cancel</button>
    </div>
  </form>
</mat-card-content>
</mat-card>
