import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, tap } from 'rxjs/operators';
import { RestServicesService } from 'src/app/services/rest-services.service';

@Component({
  selector: 'app-send-otp',
  templateUrl: './send-otp.component.html',
  styleUrls: ['./send-otp.component.scss']
})
export class SendOtpComponent {
  public otpWay: string = '';
  public showFormContent:boolean = true;
  public showResponseContent:boolean = false;
  public response:string;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dailogRef: MatDialogRef<SendOtpComponent>,
    private spinner: NgxSpinnerService,
    private service: RestServicesService) { }


  close() {
    this.dailogRef.close();
  }

  send() {
    this.spinner.show();
    const obj = {
      id: +this.data.userId,
      otpRequestFlag: this.otpWay
    }
    this.service.getOTP(obj).
    pipe(
      map((val:any)=>{
        let retunVal = '';
        if(val.statusText === 'OK'){
          retunVal = `${val.body.message}`;
        }
        return retunVal;
      }),
      tap((val)=>{
        if(val){
        this.showFormContent = false;
        this.showResponseContent = true;
        }else{
        this.showFormContent = true;
        this.showResponseContent = false;
        }
        this.spinner.hide();
      })
    ).subscribe((resp:string)=>{
      this.response = resp;
    });
  }

}
