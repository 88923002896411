import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-show-hide-account-text',
  template: `
    <div class="flex flex-row justify-start items-start gap-1">
    <span>{{accountNumber | maskAccountNumber: !showAcctNo}}</span>
    <mat-icon class="cursor-pointer self-baseline" (click)="toggleVisibility()">{{showAcctNo ? 'visibility' : 'visibility_off'}}</mat-icon>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowHideAccountTextComponent {
  @Input() accountNumber: string;

  showAcctNo = false;

  toggleVisibility(): void {
    this.showAcctNo = !this.showAcctNo;
  }
}
