/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ConfigService {

  private baseUrl = new BehaviorSubject('');
    private config = new BehaviorSubject<{}>(
        {
            user_api_url: '',
            doc_api_url: '',
            statement_api_url: '',
            account_api_url: '',
            bank_api_url: '',
            report_api_url: '',
            solr_api_url: '',
            gl_api_url: ''
        }
    );

    constructor() { }

    get configObservable() {
        return this.config.asObservable();
    }

    setBaseUrl(v:string){
      this.baseUrl.next(v);
    }

    get baseUrlObservable(){
      return this.baseUrl.asObservable();
    }

    setConfig(detail) {
        this.config.next(detail);
    }
}
