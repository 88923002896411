<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card-title>
  Add Transaction Fees
  <button mat-dialog-close class="closeIcon">
    <mat-icon>close</mat-icon>
  </button>
</mat-card-title>

<mat-card-content>
  <form [formGroup]="addTransactionFeesForm" (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
        <mat-form-field>
          <mat-label>Activity Type</mat-label>
          <mat-select formControlName="ActivityType">
            <mat-option value="">None</mat-option>
            <mat-option *ngFor="let s of activityType" [value]="s.list_item">{{s.list_item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="submitted && addTransactionFeesForm.hasError('required', ['ActivityType'])"
          class="invalid-feedback">
          Activity Type is required
        </mat-error>
      </div>
      <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
        <mat-form-field>
          <mat-label>Activity</mat-label>
          <mat-select formControlName="Activity">
            <mat-option value="">None</mat-option>
            <mat-option *ngFor="let s of activity" [value]="s.list_item">{{s.list_item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="submitted && addTransactionFeesForm.hasError('required', ['Activity'])"
          class="invalid-feedback">
          Activity is required
        </mat-error>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select formControlName="Status">
            <mat-option value="">None</mat-option>
            <mat-option *ngFor="let s of status" [value]="s.list_item">{{s.list_item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="submitted && addTransactionFeesForm.hasError('required', ['Status'])"
          class="invalid-feedback">
          Status is required
        </mat-error>
      </div>
      <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
        <mat-form-field>
          <mat-label>Flat Fee or % of Transaction</mat-label>
          <mat-select formControlName="FlatTransaction">
            <mat-option value="">None</mat-option>
            <mat-option *ngFor="let s of flatTransaction" [value]="s.list_item">{{s.list_item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="submitted && addTransactionFeesForm.hasError('required', ['FlatTransaction'])"
          class="invalid-feedback">
          Flat Fee or % of Transaction is required
        </mat-error>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
        <mat-form-field>
          <mat-label>Flat Fee / Percent</mat-label>
          <input type="text" matInput formControlName="FlatPercent" />
        </mat-form-field>
        <mat-error *ngIf="submitted && addTransactionFeesForm.hasError('required', ['FlatPercent'])"
          class="invalid-feedback">
          Flat Fee / Percent is required
        </mat-error>
        <mat-error *ngIf="submitted && addTransactionFeesForm.hasError('pattern', ['FlatPercent'])"
          class="invalid-feedback">
          Please enter valid Flat Fee / Percent
        </mat-error>
      </div>
      <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
        <mat-form-field>
          <mat-label>Fee Collection Method</mat-label>
          <mat-select formControlName="CollectionMethod">
            <mat-option value="">None</mat-option>
            <mat-option *ngFor="let s of collectionMethod" [value]="s.list_item">{{s.list_item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="submitted && addTransactionFeesForm.hasError('required', ['CollectionMethod'])"
          class="invalid-feedback">
          Fee Collection Method is required
        </mat-error>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div fxLayout="column" fxFlex="50" class="box-padding">
        <mat-form-field>
          <mat-label>Days to collect fee?</mat-label>
          <input type="text" matInput formControlName="CollectFees" />
        </mat-form-field>
        <mat-error *ngIf="submitted && addTransactionFeesForm.hasError('pattern', ['CollectFees'])"
          class="invalid-feedback">
          Please enter valid Days to collect fee
        </mat-error>
      </div>
      <div fxLayout="column" fxFlex="50" class="box-padding statusToggle labelWidth">
        <mat-slide-toggle formControlName="EnabledType"></mat-slide-toggle>
      </div>
    </div>
    <div class="button-row">
      <button mat-raised-button color="primary">Submit</button>
      <button mat-raised-button color="default" mat-dialog-close>Cancel</button>
    </div>
  </form>
</mat-card-content>
