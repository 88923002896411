<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card-title>
  Select Date Range
  <button (click)="cancelClick()" class="closeIcon">
    <mat-icon>close</mat-icon>
  </button>
</mat-card-title>
<mat-card-content>
  <form [formGroup]="dateRangeForm" (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="start start">
      <div class="box-padding mandatory">
        <mat-form-field>
          <mat-label>From(mm/dd/yyyy)</mat-label>
          <input matInput [matDatepicker]="startpicker" formControlName="startDate"
            [max]="todayDate" />
          <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
          <mat-datepicker #startpicker></mat-datepicker>
        </mat-form-field>
        <mat-error *ngIf="submitted && dateRangeForm.hasError('required',['startDate'])" class="invalid-feedback">
          From Date is required
        </mat-error>
      </div>
      <div class="box-padding mandatory">
        <mat-form-field>
          <mat-label>To(mm/dd/yyyy)</mat-label>
          <input matInput [matDatepicker]="endpicker" formControlName="endDate"
            [max]="todayDate" [min]="dateRangeForm.value.startDate" />
          <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
          <mat-datepicker #endpicker></mat-datepicker>
        </mat-form-field>
        <mat-error *ngIf="submitted && dateRangeForm.hasError('required',['endDate'])" class="invalid-feedback">
          To Date is required
        </mat-error>
      </div>
    </div>
    <div class="button-row" fxLayoutGap="10px">
      <button mat-raised-button color="primary">Apply</button>
      <button mat-raised-button color="default" (click)="cancelClick()">Cancel</button>
    </div>
  </form>
</mat-card-content>
