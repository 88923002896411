/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
export class DateUtility {
    static getPreviousDayDate(days) {
        const date = new Date();
        const lastDate = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
        return lastDate;
    }

    static getFirstDayOfMonth() {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        return firstDay;
    }
    static getFirstDayOfLastMonth() {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, m - 1, 1);
        return firstDay;
    }
    static getLastDayOfLastMonth() {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const lastDay = new Date(y, m, 1);
        return lastDay;
    }
    static getFirstDayOfYear() {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, 0, 1);
        return firstDay;
    }
    static getLastDayOfYear() {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const lastDay = new Date(y + 1, 0, 1);
        return lastDay;
    }
    static getFirstDayOfLastYear() {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y - 1, 0, 1);
        return firstDay;
    }
    static getLastDayOfLastYear() {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const lastDay = new Date(y, 0, 1);
        return lastDay;
    }
    // d is date object
    static getFirstDayOfWeek(d) {
        d = new Date(d);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }
    static getLastWeekMonToSunDate() {
        const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
        const day = beforeOneWeek.getDay();
        const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
        const lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
        const lastSunday = new Date(beforeOneWeek.setDate(diffToMonday + 6));

        return { lastMonday, lastSunday };
    }
    static getFirstDayOfSelectedMonth(year, month) {
        const y = Number(year);
        const m = Number(month);
        const firstDay = new Date(y, m, 1);
        return firstDay;
    }
    static getLastDayOfSelectedMonth(year, month) {
        const y = Number(year);
        const m = Number(month);
        const lastDay = new Date(y, m + 1, 1);
        return lastDay;
    }
    static getNextDayDate(date) {
        const d = new Date(date);
        return d.setDate(d.getDate() + 1);
    }
}
