/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[ZipMask]',
})
export class ZipMaskDirective {
    // tslint:disable-next-line: no-input-rename
    @Input('ZipMask') ZipMask = false;
    constructor(private el: ElementRef) { }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event, false);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
        this.onInputChange(event.target.value, true);
    }

    onInputChange(event, backspace) {
        if (this.ZipMask && event !== undefined) {
            let newVal = event.replace(/\D/g, '');

            if (newVal.length === 0) {
                newVal = '';
            } else if (newVal.length <= 5) {
                newVal = newVal.replace(/^(\d{0,5})/, '$1');
            } else if (newVal.length <= 10) {
                newVal = newVal.replace(/^(\d{0,5})(\d{0,4})/, '$1-$2');
            } else {
                newVal = newVal.substring(0, 10);
                newVal = newVal.replace(/^(\d{0,5})(\d{0,4})/, '$1-$2');
            }
            this.el.nativeElement.value = newVal;
        }

    }
}
