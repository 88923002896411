/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

const activityTypeList = [
  { list_item: 'P' },
  { list_item: 'A' }
];
const activityList = [
  { list_item: 'RFD' }
];
const statusList = [
  { list_item: 'E' }
];
const flatTransactionList = [
  { list_item: 'Flat Free' },
  { list_item: '% of Transaction' }
];
const collectionMethodList = [
  { list_item: 'N' },
  { list_item: 'P' }
];

@Component({
  selector: 'app-update-transaction-fees-dialog',
  templateUrl: './update-transaction-fees-dialog.component.html',
  styleUrls: ['./update-transaction-fees-dialog.component.scss']
})
export class UpdateTransactionFeesDialogComponent implements OnInit {

  activityType: Array<any>;
  activity: Array<any>;
  status: Array<any>;
  flatTransaction: Array<any>;
  collectionMethod: Array<any>;

  submitted = false;
  updateTransactionFeesForm: UntypedFormGroup;
  rowData = {} as any;
  trasactionFee: any;

  constructor(
    public dialogRef: MatDialogRef<UpdateTransactionFeesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customeSnakbar:CustomSnakbarComponent,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private service: RestServicesService
  ) { }

  ngOnInit() {
    this.GetActivityTypeAsyc();
    this.GetActivityAsyc();
    this.GetStatusAsyc();
    this.GetFlatTransactionAsyc();
    this.GetCollectionMethodAsyc();

    this.rowData = this.data.source;

    this.updateTransactionFeesForm = this.formBuilder.group({
      ActivityType: [''],
      Activity: [''],
      Status: [''],
      FlatTransaction: ['', [Validators.required]],
      FlatPercent: ['', [Validators.required, Validators.pattern('[0-9]*(.[0-9]{0,2})')]],
      CollectionMethod: ['', [Validators.required]],
      CollectFees: ['', [Validators.pattern('[0-9]*(.[0-9]{0,2})')]],
      EnabledType: ['']
    });
  }

  GetActivityTypeAsyc() {
    this.activityType = activityTypeList;
  }
  GetActivityAsyc() {
    this.activity = activityList;
  }
  GetStatusAsyc() {
    this.status = statusList;
  }
  GetFlatTransactionAsyc() {
    this.flatTransaction = flatTransactionList;
  }
  GetCollectionMethodAsyc() {
    this.collectionMethod = collectionMethodList;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.updateTransactionFeesForm.invalid) {
      return;
    }

    this.trasactionFee = {
      eventMechanism: this.updateTransactionFeesForm.value.ActivityType,
      eventAction: this.updateTransactionFeesForm.value.Activity,
      status: this.updateTransactionFeesForm.value.Status,
      percentTransaction: this.updateTransactionFeesForm.value.FlatTransaction,
      percentFee: this.updateTransactionFeesForm.value.FlatPercent,
      collectType: this.updateTransactionFeesForm.value.CollectionMethod,
      feeForStaus: this.updateTransactionFeesForm.value.CollectFees,
      enabled: this.updateTransactionFeesForm.value.EnabledType
    };

    this.spinner.show();
    this.service.updateTransactionFee(this.trasactionFee, this.rowData.id).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.customeSnakbar.openCustomSnakbar('Updated successfully', '', 'success');
        this.dialogRef.close();
      }
    });
  }

}
