<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<div>
  <mat-card class="aside mat-elevation-z8 mainCard">
    <mat-accordion multi="true" class="py-4">
      <span *ngFor="let panel of panels">
        <mat-expansion-panel *ngIf="panel.items" [expanded]="panel.expanded" [hideToggle]="hideArrowIcon">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{panel.title}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li *ngFor="let item of panel.items">
              <a *ngIf="item" [routerLink]="[item.routeLink]" [queryParams]="item.queryParams?item.queryParams:{}"
                routerLinkActive="active-link">{{item.name}}</a>
            </li>
          </ul>
        </mat-expansion-panel>
        <mat-expansion-panel class="noExpand" *ngIf="!panel.items" [hideToggle]="!hideArrowIcon">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <a [routerLink]="[panel.routeLink]" [queryParams]="panel.queryParams"
                routerLinkActive="active-link">{{panel.title}}</a>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </span>
    </mat-accordion>
  </mat-card>
</div>
