/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard/auth.guard';
import { LoginComponent } from './views/login/login.component';
import { SplitTerminalComponent } from './views/split-terminal/split-terminal.component';

import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './views/set-password/set-password.component';
import { SetSecurityQuestionsComponent } from './views/set-security-questions/set-security-questions.component';
import { ExternalRouteGuard } from './auth-guard/external-route.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: 'login',
    component: LoginComponent
  }, {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  }, {
    path: 'setPassword',
    component: SetPasswordComponent,
    canActivate: [ExternalRouteGuard]
  }, {
    path: 'setSecurityQuestions',
    component: SetSecurityQuestionsComponent,
    canActivate: [ExternalRouteGuard]
  }, {
    path: 'admin',
    loadChildren: () => import('./views/app-container/app-container.module').then(m => m.AppContainerModule),
    canActivate: [AuthGuard]
  }, {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'split-terminal',
    component: SplitTerminalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
