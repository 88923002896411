<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card-title>
  Activate Card
  <button class="closeIcon" (click)="cancelClick()">
    <mat-icon>close</mat-icon>
  </button>
</mat-card-title>

<mat-card-content>
  <form [formGroup]="activateCardForm" (ngSubmit)="onActivateCardFormSubmit()">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="100" class="box-padding mandatory">
        <mat-form-field>
          <mat-label>Pin</mat-label>
          <input type="text" matInput formControlName="pin"  />
        </mat-form-field>
        <mat-error *ngIf="submitted && activateCardForm.hasError('required',['pin'])" class="invalid-feedback">
          Pin is required
        </mat-error>
        <mat-error *ngIf="activateCardForm.hasError('alphanumeric',['pin'])">
          Please provide valid input.
        </mat-error>
      </div>
    </div>

    <div class="button-row" fxLayoutGap="10px">
      <button mat-raised-button color="primary" color="primary">Activate Card</button>
      <button mat-raised-button color="default" (click)="cancelClick()" type="button">Cancel</button>
    </div>
  </form>
</mat-card-content>
