<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card-title>
  Remove Transaction Fee
  <button mat-dialog-close class="closeIcon">
    <mat-icon>close</mat-icon>
  </button>
</mat-card-title>

<mat-card-content>
  <form (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayoutAlign="space-around center" class="removeClass">
      Are you sure you want to remove Transaction Fee?
    </div>
    <div class="button-row">
      <button mat-raised-button color="primary">Remove</button>
      <button mat-raised-button color="default" mat-dialog-close>Cancel</button>
    </div>
  </form>
</mat-card-content>
