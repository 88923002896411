/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnDestroy, OnInit } from '@angular/core';
import {RecaptchaService} from './services/recaptcha.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {RestServicesService} from './services/rest-services.service';
import {CustomSnakbarComponent} from './shared/shared-component/custom-snakbar/custom-snakbar.component';
import {AuthService} from './services/auth.service';
import {CommonRestService} from './services/common-rest.service';
import { mergeMap, of, retry, throwError } from 'rxjs';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  configLoadedObs = null;
  constructor(
    private recaptchaService: RecaptchaService,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private customeSnakbar: CustomSnakbarComponent,
    private authService: AuthService,
    private commonRestService: CommonRestService
  ) {}

  ngOnInit() {
    if (this.configLoadedObs === null) {
      this.configLoadedObs = this.authService
        .getConfig()
        .pipe(
          mergeMap((v) =>
            v.baseUrl !== "" ? of(v) : throwError(() => "Error")
          ),
          retry(2)
        )
        .subscribe({
          next: (value) => {
            this.commonRestService.setEndpointConfig(value);
          },
          error: (err) => {
            console.log(err);
          },
        });
    }
  }

  ngOnDestroy(): void {
    if (this.configLoadedObs) {
      this.configLoadedObs.unsubscribe();
    }
  }




  private recaptchaFail(): void {
    this.customeSnakbar.openCustomSnakbar(
      "Failed to get site configurations",
      "",
      "error"
    );
  }

}
