import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ReCaptchaV3Service} from 'ngx-captcha';
import {environment} from '../../environments/environment';
import {CustomSnakbarComponent} from '../shared/shared-component/custom-snakbar/custom-snakbar.component';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  siteKey: string = null;
  private recaptcha = new BehaviorSubject<string>(null);

  recaptcha$ = this.recaptcha.asObservable();

  constructor(private customeSnakbar: CustomSnakbarComponent,
              private reCaptchaV3Service: ReCaptchaV3Service) {
  }

  initialiseCaptchaByService(siteKey: string): void {
    if (siteKey) {
      this.siteKey = siteKey;
      this.recaptcha.next(null);
      this.reCaptchaV3Service.execute(siteKey, 'loinpage', token => {
        this.recaptcha.next(token);
      }, {
        useGlobalDomain: false
      }, error => {
        this.onHandleError(error);
      });
    } else {
      this.customeSnakbar.openCustomSnakbar('Unable to initialise Google Recaptcha due to invalid siteKey', 'Close', 'error');
      if (!environment.production) {
        console.log('Unable to initialise Google Recaptcha due to invalid siteKey');
      }
    }
  }

  onHandleError(error: any): void {
    if (!environment.production) {
      console.log('RecaptchaService: Recaptcha error handled here!', error);
    }
  }
}
