<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mat-elevation-z8 mainCard">
  <mat-card-title class="sectionTitle">
    Balance Parameter
  </mat-card-title>

  <mat-card-content class="sectionContent">
    <form [formGroup]="updateBalanceForm" (ngSubmit)="onSubmit()">
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
          <mat-form-field>
            <mat-label>Balance Limit $</mat-label>
            <input *ngIf="rowData !== undefined" type="text" matInput
              formControlName="MaxBalance"
              [(ngModel)]="rowData.maxBalance ? rowData.maxBalance : rowData.balanceLimit" />
            <input *ngIf="rowData === undefined" type="text" matInput 
              formControlName="MaxBalance" />
          </mat-form-field>
          <mat-error *ngIf="submitted && updateBalanceForm.hasError('required',['MaxBalance'])"
            class="invalid-feedback">
            Balance Limit is required
          </mat-error>
          <mat-error *ngIf="submitted && updateBalanceForm.hasError('pattern',['MaxBalance'])" class="invalid-feedback">
            Only number should be allowed
          </mat-error>
        </div>
        <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
          <mat-form-field>
            <mat-label>Balance Overage $</mat-label>
            <input *ngIf="rowData !== undefined" type="text" matInput
              formControlName="Overage" [(ngModel)]="rowData.balanceOverage" />
            <input *ngIf="rowData === undefined" type="text" matInput
              formControlName="Overage" />
          </mat-form-field>
          <mat-error *ngIf="submitted && updateBalanceForm.hasError('required',['Overage'])" class="invalid-feedback">
            Balance Overage is required
          </mat-error>
          <mat-error *ngIf="submitted && updateBalanceForm.hasError('pattern',['Overage'])" class="invalid-feedback">
            Only number should be allowed
          </mat-error>
        </div>
      </div>
      <div class="button-row">
        <button mat-raised-button color="primary">Update</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
