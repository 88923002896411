/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CountryNamePipe, UtcDatePipe } from './../pipes/custom.pipe';
import { ExpiryDatePipe } from './../pipes/custom.pipe';
import { CustDatePipe } from './../pipes/custom.pipe';
import { CardNoPipe } from './../pipes/custom.pipe';
import { CommaNumberPipe } from './../pipes/custom.pipe';
import { MobileNumberPipe } from './../pipes/custom.pipe';
import { YamlPipe } from './../pipes/custom.pipe';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { HeaderComponent } from './shared-component/header/header.component';
import { FooterComponent } from './shared-component/footer/footer.component';
import { CustLeftAccordianComponent } from './shared-component/cust-left-accordian/cust-left-accordian.component';
import { CustomLeftMenuComponent } from './shared-component/custom-left-menu/custom-left-menu.component';
import { BalanceParameterComponent } from '../views/program-policy/policy-parameter/balance-parameter/balance-parameter.component';
import { SingleTransactionComponent } from '../views/program-policy/rules/single-transaction/single-transaction.component';
import { MonthlyFeesComponent } from '../views/program-policy/fees/monthly-fees/monthly-fees.component';
import { TransactionFeesComponent } from '../views/program-policy/fees/transaction-fees/transaction-fees.component';
// tslint:disable-next-line: max-line-length
import { AddSingleTransactionDialogComponent } from '../views/program-policy/rules/add-single-transaction-dialog/add-single-transaction-dialog.component';
// tslint:disable-next-line: max-line-length
import { UpdateSingleTransactionDialogComponent } from '../views/program-policy/rules/update-single-transaction-dialog/update-single-transaction-dialog.component';
// tslint:disable-next-line: max-line-length
import { AddTransactionFeesDialogComponent } from '../views/program-policy/fees/add-transaction-fees-dialog/add-transaction-fees-dialog.component';
// tslint:disable-next-line: max-line-length
import { UpdateTransactionFeesDialogComponent } from '../views/program-policy/fees/update-transaction-fees-dialog/update-transaction-fees-dialog.component';
import { DailyTransactionComponent } from '../views/program-policy/account-overrides/daily-transaction/daily-transaction.component';
import { WeeklyTransactionComponent } from '../views/program-policy/account-overrides/weekly-transaction/weekly-transaction.component';
import { MonthlyTransactionComponent } from '../views/program-policy/account-overrides/monthly-transaction/monthly-transaction.component';
// tslint:disable-next-line: max-line-length
import { AddTransactionLimitDialogComponent } from '../views/program-policy/rules/add-transaction-limit-dialog/add-transaction-limit-dialog.component';
// tslint:disable-next-line: max-line-length
import { UpdateTransactionLimitDialogComponent } from '../views/program-policy/rules/update-transaction-limit-dialog/update-transaction-limit-dialog.component';
import { TransactionLimitComponent } from '../views/program-policy/rules/transaction-limit/transaction-limit.component';
// tslint:disable-next-line: max-line-length
import { RemoveTransactionFeesDialogComponent } from '../views/program-policy/fees/remove-transaction-fees-dialog/remove-transaction-fees-dialog.component';
import { TimeoutDialogComponent } from './shared-component/timeout-dialog/timeout-dialog.component';
import { SecurityDialogComponent } from './shared-component/security-dialog/security-dialog.component';
import { RemoveControlComponent } from './shared-component/remove-control/remove-control.component';
import { StatisticsTilesComponent } from './shared-component/statistics-tiles/statistics-tiles.component';
import { DateRangeDialogComponent } from './shared-component/date-range-dialog/date-range-dialog.component';
import { ZipMaskDirective } from '../directives/zip-mask.directive';
import { PhoneMaskDirective } from '../directives/phone-mask.directive';
import { DecimalNumberOnlyDirective } from '../directives/decimal-number.directive';
import { ConfirmDialogComponent } from './shared-component/confirm-dialog/confirm-dialog.component';
import { LocationDialogComponent } from './shared-component/location-dialog/location-dialog.component';
import {GoogleMapsModule} from '@angular/google-maps';
import { ShowDocumentDialogComponent } from './shared-component/show-document-dialog/show-document-dialog.component';
import { ReplaceCardDialogComponent } from './shared-component/replace-card-dialog/replace-card-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { VerifyEmailDialogComponent } from './shared-component/verify-email-dialog/verify-email-dialog.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SsnMaskDirective } from '../directives/ssn-mask.directive';
import { DateMaskDirective } from '../directives/date-mask.directive';
import { ActivateCardDialogComponent } from './shared-component/activate-card-dialog/activate-card-dialog.component';
import { MobileNumberOnlyDirective } from '../directives/mobile-number.directive';
import { SearchTableComponent } from './shared-component/search-table/search-table.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import { CurrencyInputDirective } from '../directives/currency-input.directive';
import { CustomSnakbarComponent } from './shared-component/custom-snakbar/custom-snakbar.component';
import { SuccessErrorTemplateComponent } from './shared-component/success-error-template/success-error-template.component';
import { YearMonthPickerComponent } from './shared-component/year-month-picker/year-month-picker.component';
import {MaskAccountNumberPipe} from '../pipes/mask-account-number.pipe';
import { ShowHideAccountTextComponent } from './shared-component/show-hide-account-text/show-hide-account-text.component';
import { SendOtpComponent } from './shared-component/send-otp/send-otp.component';
import { HttpClientJsonpModule } from '@angular/common/http';
import { EnabledBanksDialogComponent } from './shared-component/enabled-banks-dialog/enabled-banks-dialog.component';
import { OtpDialogComponent } from './shared-component/otp-dialog/otp-dialog.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        CustLeftAccordianComponent,
        CustomLeftMenuComponent,
        TimeoutDialogComponent,
        CountryNamePipe,
        MaskAccountNumberPipe,
        YamlPipe,
        ExpiryDatePipe,
        CustDatePipe,
        UtcDatePipe,
        CardNoPipe,
        CommaNumberPipe,
        MobileNumberPipe,
        SecurityDialogComponent,
        ZipMaskDirective,
        PhoneMaskDirective,
        DecimalNumberOnlyDirective,
        SsnMaskDirective,
        DateMaskDirective,
        MobileNumberOnlyDirective,
        CurrencyInputDirective,
        BalanceParameterComponent,
        SingleTransactionComponent,
        AddSingleTransactionDialogComponent,
        UpdateSingleTransactionDialogComponent,
        DailyTransactionComponent,
        WeeklyTransactionComponent,
        MonthlyTransactionComponent,
        TransactionLimitComponent,
        AddTransactionLimitDialogComponent,
        UpdateTransactionLimitDialogComponent,
        MonthlyFeesComponent,
        TransactionFeesComponent,
        AddTransactionFeesDialogComponent,
        UpdateTransactionFeesDialogComponent,
        RemoveTransactionFeesDialogComponent,
        RemoveControlComponent,
        StatisticsTilesComponent,
        DateRangeDialogComponent,
        ConfirmDialogComponent,
        LocationDialogComponent,
        ShowDocumentDialogComponent,
        ReplaceCardDialogComponent,
        VerifyEmailDialogComponent,
        ActivateCardDialogComponent,
        SearchTableComponent,
        CustomSnakbarComponent,
        SuccessErrorTemplateComponent,
        YearMonthPickerComponent,
        ShowHideAccountTextComponent,
        SendOtpComponent,
        EnabledBanksDialogComponent,
        OtpDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        NgxCaptchaModule,
        GoogleMapsModule,
        HttpClientJsonpModule,
        ImageCropperModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatMenuModule,
        MatListModule,
        MatCardModule,
        MatTabsModule,
        MatExpansionModule,
        MatChipsModule,
        MatIconModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatButtonModule,
        MatInputModule,
        MatSlideToggleModule,
        MatNativeDateModule,
        NgxPaginationModule,
        DragDropModule,
        NgxSpinnerModule,
        MatAutocompleteModule,
        ScrollingModule,
        MatProgressSpinnerModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        CountryNamePipe,
        YamlPipe,
        ExpiryDatePipe,
        CustDatePipe,
        UtcDatePipe,
        CardNoPipe,
        MobileNumberPipe,
        CommaNumberPipe,
        SecurityDialogComponent,
        ZipMaskDirective,
        PhoneMaskDirective,
        DecimalNumberOnlyDirective,
        SsnMaskDirective,
        DateMaskDirective,
        MobileNumberOnlyDirective,
        CurrencyInputDirective,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatMenuModule,
        MatListModule,
        MatCardModule,
        MatTabsModule,
        MatExpansionModule,
        MatChipsModule,
        MatIconModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatButtonModule,
        MatInputModule,
        MatSlideToggleModule,
        MatNativeDateModule,
        DragDropModule,
        ScrollingModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        HeaderComponent,
        FooterComponent,
        CustLeftAccordianComponent,
        CustomLeftMenuComponent,
        TimeoutDialogComponent,
        StatisticsTilesComponent,
        DateRangeDialogComponent,
        SearchTableComponent,
        BalanceParameterComponent,
        SingleTransactionComponent,
        AddSingleTransactionDialogComponent,
        UpdateSingleTransactionDialogComponent,
        DailyTransactionComponent,
        WeeklyTransactionComponent,
        MonthlyTransactionComponent,
        TransactionLimitComponent,
        AddTransactionLimitDialogComponent,
        UpdateTransactionLimitDialogComponent,
        MonthlyFeesComponent,
        TransactionFeesComponent,
        AddTransactionFeesDialogComponent,
        UpdateTransactionFeesDialogComponent,
        RemoveTransactionFeesDialogComponent,
        RemoveControlComponent,
        ConfirmDialogComponent,
        LocationDialogComponent,
        ShowDocumentDialogComponent,
        ReplaceCardDialogComponent,
        VerifyEmailDialogComponent,
        ActivateCardDialogComponent,
        CustomSnakbarComponent,
        SuccessErrorTemplateComponent,
        YearMonthPickerComponent,
        MaskAccountNumberPipe,
        ShowHideAccountTextComponent,
        SendOtpComponent,
        EnabledBanksDialogComponent
    ],
    providers: [
        CustomSnakbarComponent,
        MaskAccountNumberPipe,
        {provide:MAT_FORM_FIELD_DEFAULT_OPTIONS,useValue:{hideRequiredMarker:true}}
    ]
})
export class SharedModule { }
