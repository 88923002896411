<mat-card class="mainCard p-3">
    <mat-card-title class="sectionTitle">
        <div class="flex flex-row justify-between items-start">
            <span class="mat-card-title">Send OTP</span>
            <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
        </div>
    </mat-card-title>
    <mat-card-content *ngIf="showFormContent">
        
        <div class="flex flex-col">
            <mat-radio-group [(ngModel)]="otpWay" aria-label="Select an option">
                <mat-radio-button  [value]="'sms'">Via SMS</mat-radio-button>
                <mat-radio-button  [value]="'email'">Via Email</mat-radio-button>
            </mat-radio-group>
        </div>
        <br>
        <div class="flex flex-col justify-center items-end">
            <button [disabled]="!otpWay" type="button" (click)="send()" mat-raised-button color="primary">Send</button>
        </div>
    </mat-card-content>
    <mat-card-content *ngIf="showResponseContent">
        <div class="row flex flex-col">
            <p class="otp-response"><b>{{response}}</b></p>
        </div>
    </mat-card-content>
</mat-card>
