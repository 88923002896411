<mat-card class="mainCard max-w-[700px]">
    <mat-card-title class="sectionTitle">
      <div class="flex flex-row justify-between">
        <span><p class="mat-card-title leading-6"> {{message}} </p> </span>
        <span mat-icon-button class="relative bottom-2 pl-5" (click)="cancelClick()">
          <mat-icon class="!text-[24px] cursor-pointer">close</mat-icon>
        </span>
      </div>
    </mat-card-title>
  
    <mat-card-content>
      <form [formGroup]="validateOtpForm" (ngSubmit)="onFormSubmit()" autocomplete="off">
        <div class="flex flex-row">
          <div class="mandatory flex flex-col">
            <mat-form-field appearance="fill">
              <mat-label>Otp</mat-label>
              <input [type]="hide ? 'text' : 'password'" name="answer" matInput formControlName="answer"  autocomplete="off"/>
              <mat-icon matSuffix class="eyeball" (click)="hide = !hide">{{!hide ? 'visibility_off' :
                'visibility'}}</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="validateOtpForm.touched && validateOtpForm.hasError('required',['answer'])"
              class="invalid-feedback">
              Answer is required
            </mat-error>
            <mat-error *ngIf="validateOtpForm.hasError('alphanumeric',['answer'])">
              Please provide valid input.
            </mat-error>
          </div>
        </div>
  
        <div class="flex button-row gap-5">
          <button mat-raised-button type="button" (click)="cancelClick()" color="default" style="font-weight: 600;">Cancel</button>
          <button mat-raised-button color="primary" style="font-weight: 600;">Validate</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>