import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS} from '@angular/material-luxon-adapter';
import {DateTime} from 'luxon';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL/yyyy',
  },
  display: {
    dateInput: 'LL/yyyy',
    monthYearLabel: 'yyyy MMM',
    monthYearA11yLabel: 'yyyy MMMM',
  },
};

@Component({
  selector: 'app-year-month-picker',
  templateUrl: './year-month-picker.component.html',
  styleUrls: ['./year-month-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],

    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    }
  ]
})
export class YearMonthPickerComponent implements OnInit {
  @Input('createDate') createDate: any;
  maxDate;
  minDate: any;
  public date = new UntypedFormControl(DateTime.now());
  disableButton:boolean = false;
  constructor() {

  }

  ngOnInit(): void {
    const luxCreateDate = DateTime.fromISO(this.createDate).toJSDate();
    this.minDate = new Date(Date.UTC(luxCreateDate.getFullYear(), luxCreateDate.getMonth()));
    this.maxDate = DateTime.now();
    this.createDate = luxCreateDate;
    this.date.valueChanges.subscribe((res) => {
      if(res){
        if(this.date.hasError('matDatepickerMax') || this.date.hasError('matDatepickerMin')){
        this.disableButton = true;
        }else{
          this.disableButton = false;
        }
      }else{
        this.disableButton = true;
      }
    });

  }

  setMonthAndYear(normalizedMonthAndYear: DateTime, datepicker: MatDatepicker<any>) {
    this.date.setValue(normalizedMonthAndYear);
    datepicker.close();
  }

}
