<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<div class="loginWrapp items-center flex flex-row">
  <div class="mocaLogo justify-items-center w-[55%] order-2">
    <img src="assets/images/logo_white.jpg" alt="Moca" title="MOCA" />
  </div>
  <div class="loginInfo w-[45%] flex flex-col  self-stretch justify-center">
    <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <h1>Forgot Password</h1>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>User Name</mat-label>
          <input type="text" matInput formControlName="Username" autocomplete="off" />
        </mat-form-field>
        <mat-error *ngIf="submitted && forgotForm.hasError('required',['Username'])" class="invalid-feedback">
          User Name is required
        </mat-error>
        <mat-error *ngIf="forgotForm.hasError('alphanumeric',['Username'])">
          Please provide valid input.
        </mat-error>
      </div>
      <div class="loginBtn flex flex-col">
        <button mat-raised-button color="primary">Reset Password</button>
        <a class="underline" routerLink='/login'>Log In</a>
      </div>
    </form>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.3)" size = "medium" color = "#fff" type = "ball-beat" [fullScreen] = "true"><p style="color: white" > Please wait... </p></ngx-spinner>
