/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { CommonUtility } from '../utility/common.utility';

@Directive({
    selector: '[DateMask]',
})
export class DateMaskDirective {
    inputHasValue = false;

    @Input('DateMask') DateMask = false;
    constructor(private el: ElementRef) { }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event);
    }

    onInputChange($event) {
        let newVal = CommonUtility.covertDateToYMD($event);

        if (!newVal || (newVal === '')) {
            this.inputHasValue = false;
        } else {
            this.inputHasValue = true;
        }
        if ($event.keyCode !== 8) {
            newVal = newVal.replace(/\D/g, '');
            if (newVal.length === 0) {
                newVal = '';
            } else if (newVal.length < 2) {
                newVal = newVal.replace(/^(\d{0,2})/, '$1');
            } else if (newVal.length === 2) {
                newVal = newVal.replace(/^(\d{0,2})/, '$1/');
            } else if (newVal.length < 4) {
                newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
            } else if (newVal.length === 4) {
                newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2/');
            } else if (newVal.length <= 10) {
                newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
            } else {
                newVal = newVal.substring(0, 10);
                newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
            }
            $event.target.value = newVal;
        }
    }
}
