/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RestServicesService } from './services/rest-services.service';
import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './auth-guard/auth.guard';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ConfigService } from './services/config.service';
import { LoginComponent } from './views/login/login.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './views/set-password/set-password.component';
import { SetSecurityQuestionsComponent } from './views/set-security-questions/set-security-questions.component';
import { MobileNumberPipe } from './pipes/custom.pipe';
import { ExternalRouteGuard } from './auth-guard/external-route.guard';
import {DeviceDetectorService} from 'ngx-device-detector';
import { JsEncryptWrapperService } from './services/js-encrypt-wrapper.service';
import {NgxTurnstileModule} from 'ngx-turnstile';

// import { SplitTerminalComponent } from './views/split-terminal/split-terminal.component';
// import { TerminalDialogComponent } from './views/terminal-dialog/terminal-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgxTurnstileModule
    ],
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SetPasswordComponent,
    SetSecurityQuestionsComponent,
    // SplitTerminalComponent,
    // TerminalDialogComponent
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    DataService,
    RestServicesService,
    AuthService,
    AuthGuard,
    ExternalRouteGuard,
    MobileNumberPipe,
    ConfigService,
    JsEncryptWrapperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    DeviceDetectorService
  ]
})
export class AppModule { }
