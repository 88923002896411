<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mat-elevation-z8 mainCard">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" class="sectionTitle">
    <span>Weekly Transaction Limit</span>
    <a mat-raised-button color="primary" pull-right (click)="addTransactionLimitDialog()"> Add </a>
  </mat-card-title>

  <mat-card-content class="sectionContent">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="totalSpend programHead" *ngIf="dataSource.length!==0">
      <div fxLayout="column" fxFlex="25" class="displayData">
        <label>MCC Type</label>
      </div>
      <div fxLayout="column" fxFlex="25" class="displayData">
        <label>Amount</label>
      </div>
      <div fxLayout="column" fxFlex="25" class="displayData">
        <label>Count</label>
      </div>
      <div fxLayout="column" fxFlex="25" class="displayData">
        <label>Help</label>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around start" class="programTable"
      *ngFor="let s of dataSource | paginate: {totalItems: paginationConfig.TotalItems, itemsPerPage: paginationConfig.ItemsPerPage, currentPage: paginationConfig.CurrentPage}; index as index">
      <div fxLayout="column" fxFlex="25" class="displayData programContent">
        <span>{{merchantType[s.mccType]}}</span>
      </div>
      <div fxLayout="column" fxFlex="25" class="displayData programContent">
        <span *ngIf="s.amount">$ {{s.amount | number:'.2'}}</span>
      </div>
      <div fxLayout="column" fxFlex="25" class="displayData programContent">
        <span>{{s.count}}</span>
      </div>
      <div fxLayout="column" fxFlex="25" class="displayData programContent">
        <span>
          <button class="threeDot" mat-flat-button [matMenuTriggerFor]="weekmenu"><i class="m-ellipsis-v"></i></button>
          <mat-menu #weekmenu="matMenu">
            <a mat-menu-item (click)="removeTransactionLimitDialog(s, index)">Delete</a>
            <a mat-menu-item (click)="updateTransactionLimitDialog(s, index)">Edit</a>
          </mat-menu>
        </span>
      </div>
    </div>
    <div class="empty-data" *ngIf="dataSource.length===0">No Data Available</div>
    <pagination-controls class="common-pagination" (pageChange)="pageChanged($event)" autoHide="true">
    </pagination-controls>
  </mat-card-content>
</mat-card>
