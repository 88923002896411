<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<header>
  <div class="container">
    <div class="flex flex-row justify-between items-center pt-0.5 pb-0.5">
      <div>
        <a routerLink='/admin/dashboard' class="logo">
          <img src="../../../assets/images/logo_v1.png" alt="Moca Financial" title="Moca Financial" />
        </a>
      </div>
      <nav>


        <a mat-flat-button routerLink="/admin/program-policies" routerLinkActive="active-link" class="headerUserButton"
          *ngIf="menus != undefined && menus['menu.bank']==='view'">Bank</a>

        <a mat-flat-button routerLink="/admin/users" routerLinkActive="active-link" class="headerUserButton"
          *ngIf="menus != undefined && menus['menu.users']==='view'">Users</a>

        <button mat-flat-button [matMenuTriggerFor]="supportMenu" *ngIf="menus != undefined && menus['menu.support']==='view'">Customer
          Support</button>
        <mat-menu #supportMenu="matMenu" [overlapTrigger]="false">

          <a mat-menu-item routerLink="/admin/support/customer-search" routerLinkActive="active-link"
            *ngIf="menus != undefined && menus['menu.support.customersearch']==='view'">
            <span>Customer Search</span>
          </a>
          <a mat-menu-item routerLink="/admin/support/bank-adjustment" routerLinkActive="active-link"
            *ngIf="menus != undefined && menus['menu.support.bankadjustment']==='view'">
            <span>Bank Adjustment</span>
          </a>

         <a mat-menu-item routerLink="/admin/support/transaction-search" routerLinkActive="active-link"
            *ngIf="menus != undefined && menus['menu.support.txsearch']==='view'">
            <span>Transaction Search</span>
          </a>

          <a mat-menu-item routerLink="/admin/support/bank-transaction" routerLinkActive="active-link" *ngIf="menus != undefined && menus['menu.support.banktransaction']==='view'">
            <span>Bank Transaction</span>
          </a>
          <button mat-menu-item [matMenuTriggerFor]="pendingApprovals" *ngIf="menus != undefined && menus['menu.support.pendingapprovals']==='view'">Pending
            Approvals</button>
        </mat-menu>

<!-- Pending Approvals -->

        <mat-menu #pendingApprovals="matMenu" [overlapTrigger]="false">

          <a mat-menu-item routerLink="/admin/pendingapprovals/onboardingapplications" routerLinkActive="active-link" *ngIf="menus != undefined && menus['menu.support.pendingapprovals.onboardingapplications'] === 'view'">
            <span>Onboarding Applications</span>
          </a>

          <a mat-menu-item routerLink="/admin/support/hold-adjustment" routerLinkActive="active-link"
          *ngIf="menus != undefined && menus['menu.support.pendingapprovals.holdadjustments'] === 'view'">
          <span>Hold Adjustment</span>
        </a>
        <a mat-menu-item routerLink="/admin/support/schedule-appointment" routerLinkActive="active-link"
          *ngIf="menus && menus['menu.support.pendingapprovals.scheduleappointment'] === 'view'">
          <span>Schedule Appointment</span>
        </a>
        <a mat-menu-item routerLink="/admin/support/print-card" routerLinkActive="active-link"
        *ngIf="menus && menus['menu.support.pendingapprovals.printcard'] === 'view'">
        <span>Print Card</span>
      </a>


        </mat-menu>

        <button mat-flat-button [matMenuTriggerFor]="reportMenu" *ngIf="menus != undefined &&  menus['menu.report']==='view'">Reports</button>
        <mat-menu #reportMenu="matMenu" [overlapTrigger]="false">
          <span *ngFor="let item of reportMenus">
            <a mat-menu-item routerLink="/admin/reports" [queryParams]="{group: item.code}"
              routerLinkActive="active-link" *ngIf="menus != undefined && menus['menu.report.'+item.code]==='view'">
              <span>{{item.description}}</span>
            </a>
          </span>
          <a mat-menu-item routerLink="/admin/external-report" routerLinkActive="active-link"
            *ngIf="menus != undefined && menus['menu.er']==='view'"><span>External Report</span></a>
        </mat-menu>

        <button mat-flat-button [matMenuTriggerFor]="otherMenu" *ngIf="menus != undefined && menus['menu.others']==='view'">Others</button>
        <mat-menu #otherMenu="matMenu" [overlapTrigger]="false">
          <a mat-menu-item routerLink="/admin/docs" routerLinkActive="active-link">
            <span>Documents</span>
          </a>
          <a mat-menu-item routerLink="/admin/admin-query" routerLinkActive="active-link" *ngIf="menus != undefined &&  menus['menu.others.query']==='view'">
            <span>Query</span>
          </a>
          <a mat-menu-item routerLink="/admin/split-terminal" routerLinkActive="active-link" *ngIf="menus != undefined &&  menus['menu.others.commandsite']==='view'">
            <span>Run Script</span>
          </a>
        </mat-menu>

        <button mat-flat-button [matMenuTriggerFor]="mocasystemMenu" *ngIf="menus != undefined && menus['menu.others']==='view'">MOCA System</button>
        <mat-menu #mocasystemMenu="matMenu" [overlapTrigger]="false">
          <a mat-menu-item routerLink="/admin/messages" routerLinkActive="active-link"
            *ngIf="menus != undefined &&  menus['menu.others.messages']==='view'">
            <span>Messages</span>
          </a>
        </mat-menu>
      </nav>
      <div>
        <a [matMenuTriggerFor]="UserMenu" class="profileBtn">
          <span><i class="m-user"></i></span> <b> {{name}} </b>
        </a>
        <mat-menu class="user-option" #UserMenu="matMenu">
          <h4 class="p-[15px] font-normal text-xl">{{name}}</h4>
          <mat-divider></mat-divider>
          <a routerLink='/admin/user-profile' mat-menu-item routerLinkActive="active-link">Profile</a>
          <a routerLink='/admin/change-password' mat-menu-item routerLinkActive="active-link">Change Your Password</a>
          <a routerLink='/admin/change-securityqa' mat-menu-item routerLinkActive="active-link">Change Your Security
            Q&A</a>
          <a href="mailto:support@mocapay.com" mat-menu-item>Contact</a>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="onLogout()">Logout</button>
        </mat-menu>
      </div>
    </div>
  </div>
</header>
