/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../services/data.service';
import { CommonUtility } from '../utility/common.utility';
import YAML from 'yaml';
import {DateTime} from 'luxon';

@Pipe({ name: 'CountryName' })
export class CountryNamePipe implements PipeTransform {
    countryObservable = null;
    constructor(private dataservice: DataService, private commonApi: CommonUtility) { }

    transform(countryCode: string) {
        const data = new BehaviorSubject<string>(countryCode);
        if (countryCode) {
            let countryLookup = this.dataservice.countryList;
            if (countryLookup.length === 0) {
                this.countryObservable = this.dataservice.countryObservable.subscribe(resp => {
                    if (!CommonUtility.isEmptyObj(resp)) {
                        countryLookup = resp;
                        this.dataservice.setCountryData(countryLookup);
                        const index = countryLookup.findIndex(p => p.code === countryCode);
                        if (index !== -1) { data.next(countryLookup[index].description); }
                    } else {
                        this.commonApi.GetCountries();
                    }
                });
            } else {
                if (this.countryObservable) { this.countryObservable.unsubscribe(); }
                const index = countryLookup.findIndex(p => p.code === countryCode);
                if (index !== -1) { data.next(countryLookup[index].description); }
            }
        }
        return data.asObservable();
    }
}

@Pipe({ name: 'cardNo' })
export class CardNoPipe implements PipeTransform {
    transform(cardNo: string) {
        if (cardNo) {
            cardNo = [cardNo.slice(0, 4), ' ', cardNo.slice(4, 8),
                ' ', cardNo.slice(8, 12), ' ', cardNo.slice(12, 16)].join('');
        }
        return cardNo;
    }
}

@Pipe({ name: 'mobilenum' })
export class MobileNumberPipe implements PipeTransform {
    transform(anyNumber: string) {
        if (anyNumber && anyNumber.length > 9) {
            anyNumber = anyNumber.replace(/-/g, '');
            anyNumber = anyNumber.replace(/ /g, '');
            anyNumber = [anyNumber.slice(0, 3), '-', anyNumber.slice(3, 6),
                '-', anyNumber.slice(6, 10)].join('');
        }
        return anyNumber;
    }
}
@Pipe({ name: 'yaml' })
  export class YamlPipe implements PipeTransform {
    transform(value:any): any {
        value=value.replaceAll('“','"').replaceAll('”','"')
        if(!value)
        return ''
        else return YAML.stringify(JSON.parse(value));
    }
  }
@Pipe({ name: 'commaNum' })
export class CommaNumberPipe implements PipeTransform {
    transform(anyNumber: string) {
        if (anyNumber) {
            anyNumber = anyNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return anyNumber;
    }
}

@Pipe({ name: 'expDate' })
export class ExpiryDatePipe implements PipeTransform {
    transform(date: string) {
        if (date) {
            const arry = date.split('T');
            date = arry[0] + 'T10:04:01Z';
            date = new Date(date).getMonth() + 1 + '/' +
                new Date(date).getFullYear().toString().substring(2);
        }
        return date;
    }
}

@Pipe({ name: 'custDate' })
export class CustDatePipe implements PipeTransform {
    transform(date: any) {
        if (date !== undefined) {
            if (typeof date === 'object') {
                date = date.toISOString();
            }
            if (date && date !== '') {
                const arry = date.split('T');
                date = arry[0] + 'T10:04:01Z';
                date = new Date(date).toLocaleDateString();
            }
            return date;
        }
    }
}

//******Convert Date to full fill the american time issue*****

@Pipe({
    name:'utcDate'
})
export class UtcDatePipe implements PipeTransform{
  transform(date: DateTime | Date): any {
    let jsDate: Date;
    if (date instanceof DateTime) {
      jsDate = date.toJSDate();
    } else {
      jsDate = date;
    }
    return new Date(Date.UTC(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate(), jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds()))
  }
}



