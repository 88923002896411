<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mainCard">
  <mat-card-title class="sectionTitle">
    <div class="flex flex-row justify-between" style="line-height: 80%;">
      <p class="mat-card-title"> {{question}} </p>
      <span mat-icon-button class="closeIcon" (click)="cancelClick()">
        <mat-icon>close</mat-icon>
      </span>
    </div>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="validateSecurityForm" (ngSubmit)="onFormSubmit()" autocomplete="off">
      <div class="flex flex-row">
        <div class="mandatory flex flex-col">
          <mat-form-field appearance="fill">
            <mat-label>Answer</mat-label>
            <input [type]="hide ? 'text' : 'password'" name="answer" matInput formControlName="answer" autocomplete="off"/>
            <mat-icon matSuffix class="eyeball" (click)="hide = !hide">{{!hide ? 'visibility_off' :
              'visibility'}}</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="validateSecurityForm.touched && validateSecurityForm.hasError('required',['answer'])"
            class="invalid-feedback">
            Answer is required
          </mat-error>
          <mat-error *ngIf="validateSecurityForm.hasError('alphanumeric',['answer'])">
            Please provide valid input.
          </mat-error>
        </div>
      </div>

      <div class="button-row">
        <button mat-raised-button color="primary" style="font-weight: 600;">Validate</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>