/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit } from '@angular/core';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ForgotPassword } from 'src/app/app-dto/rules.dto';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigService } from 'src/app/services/config.service';
import { AuthService } from 'src/app/services/auth.service';
import { CustomValidators } from 'src/app/validators/custom.validators';
import { CommonRestService } from 'src/app/services/common-rest.service';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./../login/login.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  submitted = false;
  forgotForm: UntypedFormGroup;
  user: ForgotPassword;

  constructor(
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private customeSnakbar:CustomSnakbarComponent,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.user = { username: '' };
  }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      Username: ['', [Validators.required, CustomValidators.alphanumeric]]
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }
    this.user = { username: this.forgotForm.value.Username.trim() };
    this.spinner.show();
    this.service.adminForgotPassword(this.user).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.router.navigate(['/login']);
        this.customeSnakbar.openCustomSnakbar('Password sent successfully to your registered email', '', 'success');
      }
    });
  }
}
