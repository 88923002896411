<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card-title>
  Update Transaction Limit Control
  <button mat-dialog-close class="closeIcon">
    <mat-icon>close</mat-icon>
  </button>
</mat-card-title>

<mat-card-content>
  <form [formGroup]="updateTransactionLimitForm" (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div fxLayout="column" fxFlex="50" class="box-padding">
        <mat-form-field>
          <mat-label>Merchant Type</mat-label>
          <input type="text" matInput formControlName="MccType"
            [(ngModel)]="merchantType[rowData.mccType]" />
        </mat-form-field>
        <mat-error *ngIf="updateTransactionLimitForm.hasError('alphanumeric',['MccType'])">
          Please provide valid input.
        </mat-error>
      </div>
      <div fxLayout="column" fxFlex="50" class="box-padding">
        <mat-form-field>
          <mat-label>Amount ($)</mat-label>
          <input type="text" matInput formControlName="Amount" [(ngModel)]="rowData.amount"
            [maxLength]="25" [MobileNumberOnly]="true" />
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="50" class="box-padding">
        <mat-form-field>
          <mat-label>Count</mat-label>
          <input type="text" matInput formControlName="Count" [(ngModel)]="rowData.count" />
        </mat-form-field>
        <mat-error *ngIf="submitted && updateTransactionLimitForm.hasError('pattern', ['Count'])"
          class="invalid-feedback">
          Please enter valid Count
        </mat-error>
      </div>
    </div>
    <div class="button-row" fxLayoutGap="10px">
      <button mat-raised-button color="primary">Update</button>
      <button mat-raised-button color="default" mat-dialog-close>Cancel</button>
    </div>
  </form>
</mat-card-content>
