/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
  cardList = [];
  accountListDetail = {};
  private countryListData = [];
  defaultAccount: any;

  private merchantTypeData = new BehaviorSubject({});
  private intrumentDetail = new BehaviorSubject({});
  private merchantNameData = new BehaviorSubject({});
  private actionData = new BehaviorSubject({});
  private sendAlertData = new BehaviorSubject({});
  private allowData = new BehaviorSubject({});
  private amountType = new BehaviorSubject({});
  private changeTitle = new BehaviorSubject('MOCA Financials');
  private otp = new BehaviorSubject('');
  private cardListData = new BehaviorSubject<Array<any>>([]);
  private countryData = new BehaviorSubject<Array<any>>([]);
  private roleData = new BehaviorSubject([]);
  private appsData = new BehaviorSubject([]);
  private appTypesData = new BehaviorSubject([]);
  private banksData = new BehaviorSubject([]);
  private sourceData = new BehaviorSubject([]);
  private eventActionData = new BehaviorSubject([]);
  private cardsStatusData = new BehaviorSubject([]);
  private partyStatusData = new BehaviorSubject([]);
  private kycStatusData = new BehaviorSubject([]);
  private ofacStatusData = new BehaviorSubject([]);
  private docMetaData = new BehaviorSubject([]);
  private transactionStatusData = new BehaviorSubject([]);
  private entityTypeData = new BehaviorSubject([]);
  private businessTypeData = new BehaviorSubject([]);
  private frequencyData = new BehaviorSubject([]);
  private suffixData = new BehaviorSubject([]);
  private titlesData = new BehaviorSubject([]);
  private flagsData = new BehaviorSubject({});
  private reportMenus = new BehaviorSubject([]);

  private isPrimeUser = new BehaviorSubject<boolean>(false);
  private productType = new BehaviorSubject<string>('');
  private accountDetail = new BehaviorSubject<object>({});
  private accountList = new BehaviorSubject<Array<any>>([]);
  private cardHistoryDetail = {} as any;
  private customerList = new BehaviorSubject<Array<any>>([]);
  private transactionList = new BehaviorSubject<Array<any>>([]);
  private bankDetail = new BehaviorSubject<object>({});
  private searchHistory = new BehaviorSubject<object>({});
  private transactionHistory = new BehaviorSubject<object>({});
  private accountId = new BehaviorSubject<string>('');
  private shipTypeList = new BehaviorSubject<Array<any>>([]);
  private fraudStatusList = new BehaviorSubject([]);

  private configLoaded = new BehaviorSubject<boolean>(false);

  obsMerchantTypedata = this.merchantTypeData.asObservable();
  obsIntrumentDetail = this.intrumentDetail.asObservable();
  obsMerchantNamedata = this.merchantNameData.asObservable();
  obsActiondata = this.actionData.asObservable();
  obsSendAlertdata = this.sendAlertData.asObservable();
  obsAllowdata = this.allowData.asObservable();
  obsAmountType = this.amountType.asObservable();
  obsChangeTitle = this.changeTitle.asObservable();
  obsOTP = this.otp.asObservable();
  obsRolesdata = this.roleData.asObservable();
  obsAppsdata = this.appsData.asObservable();
  obsAppTypesdata = this.appTypesData.asObservable();
  obsBanksdata = this.banksData.asObservable();
  obsSourcedata = this.sourceData.asObservable();
  obsEventActiondata = this.eventActionData.asObservable();
  obsCardsStatusdata = this.cardsStatusData.asObservable();
  obsPartyStatusdata = this.partyStatusData.asObservable()
  obsKYCStatusdata = this.kycStatusData.asObservable();
  obsOFACStatusdata = this.ofacStatusData.asObservable();
  obsDocMetadata = this.docMetaData.asObservable();
  obsTransactionStatusdata = this.transactionStatusData.asObservable();
  obsEntityTypedata = this.entityTypeData.asObservable();
  obsBusinessTypedata = this.businessTypeData.asObservable();
  obsFrequencyData = this.frequencyData.asObservable();
  obsSuffixData = this.suffixData.asObservable();
  obsTitlesData = this.titlesData.asObservable();
  obsFlagsData = this.flagsData.asObservable();
  obsReportMenus = this.reportMenus.asObservable();
  obsAccountId = this.accountId.asObservable();
  obsShipTypeListObservable = this.shipTypeList.asObservable();
fraudListDataObjervable = this.fraudStatusList.asObservable();
configLoadedObservable = this.configLoaded.asObservable();


  merchantType(data: object) {
    this.merchantTypeData.next(data);
  }
  setIntrumentDetail(data: object) {
    this.intrumentDetail.next(data);
  }
  merchantName(data: object) {
    this.merchantNameData.next(data);
  }
  action(data: object) {
    this.actionData.next(data);
  }
  sendAlert(data: object) {
    this.sendAlertData.next(data);
  }
  allowed(data: object) {
    this.allowData.next(data);
  }
  amounttype(data: object) {
    this.amountType.next(data);
  }
  setTitle(title: string) {
    this.changeTitle.next(title);
  }
  setOTP(otp: string) {
    this.otp.next(otp);
  }
  setCountryData(list) {
    this.countryListData = [].concat(list);
    this.countryData.next(list);
  }
  roles(data: any) {
    this.roleData.next(data);
  }
  apps(data: any) {
    this.appsData.next(data);
  }
  apptypes(data: any) {
    this.appTypesData.next(data);
  }
  banks(data: any) {
    this.banksData.next(data);
  }
  sources(data: any) {
    this.sourceData.next(data);
  }
  eventActions(data: any) {
    this.eventActionData.next(data);
  }
  cardsStatus(data: any) {
    this.cardsStatusData.next(data);
  }
  partyStatus(data: any) {
    this.partyStatusData.next(data);
  }
  kycStatus(data: any) {
    this.kycStatusData.next(data);
  }
  ofacStatus(data: any) {
    this.ofacStatusData.next(data);
  }
  docMetadata(data: any) {
    this.docMetaData.next(data);
  }
  transactionStatus(data: any) {
    this.transactionStatusData.next(data);
  }
  entityType(data: any) {
    this.entityTypeData.next(data);
  }
  businessType(data: any) {
    this.businessTypeData.next(data);
  }
  frequencyLookupData(data: any) {
    this.frequencyData.next(data);
  }
  suffixLookupData(data: any) {
    this.suffixData.next(data);
  }
  titlesLookupData(data: any) {
    this.titlesData.next(data);
  }
  setFlagsData(data: any) {
    this.flagsData.next(data);
  }
  setReportMenus(data: any) {
    this.reportMenus.next(data);
  }
  setAccountId(data: any) {
    this.accountId.next(data);
  }


  setFraudStatusListData(data:any){
    this.fraudStatusList.next(data);
  }

  get countryObservable() {
    return this.countryData.asObservable();
  }
  get countryList() {
    return this.countryListData;
  }
  get cardListDataObservable() {
    return this.cardListData.asObservable();
  }
  get primeUserObservable() {
    return this.isPrimeUser.asObservable();
  }
  get productTypeObservable() {
    return this.productType.asObservable();
  }
  get accountDetailObservable() {
    return this.accountDetail.asObservable();
  }
  get accountListObservable() {
    return this.accountList.asObservable();
  }
  get SelectCardHistory() {
    return this.cardHistoryDetail;
  }

  get customerListObservable() {
    return this.customerList.asObservable();
  }
  get transactionListObservable() {
    return this.transactionList.asObservable();
  }
  get bankDetailObservable() {
    return this.bankDetail.asObservable();
  }
  get searchHistoryObservable() {
    return this.searchHistory.asObservable();
  }
  get transactionHistoryObservable() {
    return this.transactionHistory.asObservable();
  }


  setCardListData(list) {
    this.cardList = list;
    this.cardListData.next(list);
  }
  setProductType(val) {
    if (val === 'P' || val === 'M' || val === 'APX' || val === 'ELI') {
      this.isPrimeUser.next(true);
    } else {
      this.isPrimeUser.next(false);
    }
    this.productType.next(val);
  }
  setAccountDetail(resp) {
    this.accountListDetail = resp;
    this.accountDetail.next(resp);
  }
  setAccountList(resp) {
    this.accountList.next(resp);
  }
  setCustomerList(resp) {
    this.customerList.next(resp);
  }
  setTransactionList(resp) {
    this.transactionList.next(resp);
  }
  setSelectCardHistory(cardDetail) {
    this.cardHistoryDetail = cardDetail;
  }

  setBankDetail(resp) {
    this.bankDetail.next(resp);
  }
  setSearchHistory(resp) {
    this.searchHistory.next(resp);
  }
  setTransactionHistory(resp) {
    this.transactionHistory.next(resp);
  }
  setShipTypeList(list) {
    this.shipTypeList.next(list);
  }

  // Main account Observable
  mainAccountObservable = new BehaviorSubject({});
  setMainAccountObservable(val) {
    this.mainAccountObservable.next(val);
  }
  getMainAccountObservable() {
    return this.mainAccountObservable.asObservable();
  }

  // getting and setting configLoaded
  setCongigLoaded(val){
    this.configLoaded.next(val);
  }



  clear() {
    this.merchantType([]);
    this.merchantName([]);
    this.amounttype([]);
    this.action([]);
    this.sendAlert([]);
    this.allowed([]);
    this.setCardListData([]);
    this.setCountryData([]);
    this.roles([]);
    this.apps([]);
    this.apptypes([]);
    this.banks([]);
    this.sources([]);
    this.eventActions([]);
    this.cardsStatus([]);
    this.transactionStatus([]);
    this.entityType([]);
    this.businessType([]);
    this.setFlagsData({});
    this.suffixLookupData([]);
    this.titlesLookupData([]);
    this.kycStatus([]);
    this.ofacStatus([]);
    this.docMetadata([]);
    this.setIntrumentDetail({});
    this.setAccountDetail({});
    this.setBankDetail({});
    this.setAccountList([]);
    this.frequencyLookupData([]);
    this.setProductType('');
    this.setShipTypeList([]);
  }
}
