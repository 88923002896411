import { Component, Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { CustomValidators } from 'src/app/validators/custom.validators';
import { CustomSnakbarComponent } from '../custom-snakbar/custom-snakbar.component';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss']
})
export class OtpDialogComponent {
  submitted = false;
  validateOtpForm: UntypedFormGroup;
  message = '';
  username: string;
  hide = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<OtpDialogComponent>,
    private service: RestServicesService,
    private fb: UntypedFormBuilder,
    public customeSnakbar:CustomSnakbarComponent,
    private spinner: NgxSpinnerService
  ) {
    if (this.data) {
      this.message = this.data.message;
      this.username = this.data.username;
    }

    this.validateOtpForm = this.fb.group({
      answer: [null, [Validators.required, CustomValidators.alphanumeric]],
    });
  }




  get f() { return this.validateOtpForm.controls; }


  validateOtp() {
    const postData = {
      userOtp: this.f.answer.value
    };

    this.spinner.show();
    this.service.validateOtp(postData).subscribe((otpResp:HttpResponse<any>) => {
      if(otpResp.status === 200 && otpResp.ok)
      this.dialogRef.close(true);
    });
  }

  onFormSubmit() {
    this.submitted = true;

    if (this.validateOtpForm.invalid) {
      return;
    }
    this.validateOtp();
  }

  cancelClick() {
    this.dialogRef.close(false);
  }

}
