/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[MobileNumberOnly]'
})
export class MobileNumberOnlyDirective {
    // Allow integers and negative values
    private regex = new RegExp(/^[-+]?\d+$/);

    @Input('MobileNumberOnly') mobileNumberOnly = true;
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home  <'ArrowLeft', 'ArrowRight'>
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.mobileNumberOnly) {
            let next: string;
            if (this.specialKeys.indexOf(event.key) !== -1) {
                return;
            }
            const current: string = this.el.nativeElement.value;
            if (current !== undefined) {
                next = current.concat(event.key);
            }
            if ((next && !String(next).match(this.regex))) {
                event.preventDefault();
            }
        }
    }
}
