/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-monthly-fees',
  templateUrl: './monthly-fees.component.html',
  styleUrls: ['./monthly-fees.component.scss']
})
export class MonthlyFeesComponent implements OnInit {
  submitted = false;
  updateMonthlyForm: UntypedFormGroup;
  rowData = {} as any;
  policyId: number;
  accountId: number;
  controlId: number;
  constructor(
    private customeSnakbar:CustomSnakbarComponent,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
      this.accountId = params.accountId;
      if (this.accountId === undefined) {
        this.GetPolicy();
      } else {
        this.GetAccountControls();
      }
    });
    this.updateMonthlyForm = this.formBuilder.group({
      MonthlyFee: ['', Validators.pattern('[0-9]+')],
      NonUseFee: ['', Validators.pattern('[0-9]+')],
      NonUseDays: ['', Validators.pattern('[0-9]+')]
    });
  }

  GetPolicy() {
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
    });

    this.service.getPolicy(this.policyId).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.rowData = resp.body;
      }
    });
  }
  GetAccountControls() {
    this.spinner.show();

    this.service.getAccountControls(this.accountId).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.rowData = resp.body.data[0];
        if (this.rowData !== undefined) {
          this.controlId = this.rowData.id;
          // below three lines will be deprecated after sometimes
          this.rowData.monthlyNonuseFee = this.rowData.monthlyNonUseFee;
          this.rowData.monthlyNonuseDays = this.rowData.monthlyNonUseDays;
        }
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.updateMonthlyForm.invalid) {
      return;
    }

    if (this.accountId === undefined) {
      this.rowData = {
        monthlyFee: Number(this.updateMonthlyForm.value.MonthlyFee),
        monthlyNonuseFee: Number(this.updateMonthlyForm.value.NonUseFee),
        monthlyNonuseDays: Number(this.updateMonthlyForm.value.NonUseDays)
      };

      this.spinner.show();
      this.service.updateProgramPolicy(this.rowData, this.policyId).subscribe((resp: any) => {
        this.spinner.hide();
        if (resp.status === 200) {
          this.customeSnakbar.openCustomSnakbar('Updated successfully', '', 'success');
        }
      });
    } else {
      // below this.rowData lines will be deprecated after sometimes
      this.rowData = {
        entityId: Number(this.accountId),
        entityType: 'AP',
        monthlyFee: Number(this.updateMonthlyForm.value.MonthlyFee),
        monthlyNonUseFee: Number(this.updateMonthlyForm.value.NonUseFee),
        monthlyNonUseDays: Number(this.updateMonthlyForm.value.NonUseDays)
      };

      this.spinner.show();
      if (this.controlId) {
        this.service.updateAccountControls(this.rowData).subscribe((resp: any) => {
          this.spinner.hide();
          if (resp.status === 200) {
            this.customeSnakbar.openCustomSnakbar('Updated successfully', '', 'success');
          }
        });
      } else {
        this.service.addAccountControls(this.rowData).subscribe((resp: any) => {
          this.spinner.hide();
          if (resp.status === 200) {
            this.customeSnakbar.openCustomSnakbar('Added successfully', '', 'success');
          }
        });
      }

    }
  }
}
