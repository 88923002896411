<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mainCard">

  <mat-card-title *ngIf="showTitle" class="flex justify-between sectionTitle">
    <span>{{data?.title}}</span>
    <mat-icon mat-dialog-close class="closeIcon">close</mat-icon>
  </mat-card-title>

  <mat-card-content>
    <h6>
      {{message}}
    </h6>
    <div class="gap-x-2.5 flex justify-end">
      <button mat-raised-button color="primary" *ngIf="!showOkButton" (click)="onYesClick()">Confirm</button>
      <button mat-raised-button color="default" *ngIf="!showOkButton && !showTitle" (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="default" *ngIf="showOkButton" (click)="onNoClick()">Close</button>
    </div>
  </mat-card-content>
</mat-card>
