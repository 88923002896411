/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { CommonUtility } from 'src/app/utility/common.utility';
import { DataService } from 'src/app/services/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-statistics-tiles',
  templateUrl: './statistics-tiles.component.html',
  styleUrls: ['./statistics-tiles.component.scss']
})
export class StatisticsTilesComponent implements OnInit, OnDestroy {

  currentMonth = false;
  currentDay = false;
  userDetail: any = {};
  accountDetailObservable = null;
  productTypeObservable = null;
  accountListObservable = null;
  obsIntrumentDetail = null;
  bankDetailObservable = null;
  obsAccountId = null;
  productType = '';
  productTypeName = '';
  mainAccount: any = {};
  accountsList = [];
  showAddAccount = false;
  showRefreshBtn = false;
  showRegisterGift = false;
  csId: number;
  title: any;
  isPrimeUser: Observable<boolean>;
  accountId: number;
  bankId: number;
  partyId: number;
  listLength = 0;
  bankList = [];
  selectedBank = {} as any;
  queryParameters: any;
  selectedAccount: any;
  back: boolean = false;
  bank_name:any;
  @Output('eventOnBankTransaction') eventOnBankTransaction = new EventEmitter();

  constructor(
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private route: ActivatedRoute,
    private dataservice: DataService,
    private router: Router,
    private restService: RestServicesService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.csId = params.csId;
      this.queryParameters = params;
      this.back = (params.back)?true:false;
    });

    if (this.obsIntrumentDetail === null) {
      this.obsIntrumentDetail = this.dataservice.obsIntrumentDetail.subscribe((data: any) => {
        if (!CommonUtility.isEmptyObj(data)) {
          this.selectedAccount = data;
          this.isPrimeUser = this.dataservice.primeUserObservable;
          this.getProductType();
          this.getParty();
          this.getSelfAccount();
        } else {
          this.isPrimeUser = this.dataservice.primeUserObservable;
          this.getProductType();
          this.getParty();
          this.getSelfAccount();
        }
      });
    }
  }

  getBankDetail() {
    if (this.bankDetailObservable === null) {
      this.bankDetailObservable = this.dataservice.bankDetailObservable.subscribe((data: any) => {
        if (!CommonUtility.isEmptyObj(data)) {
          this.selectedBank = data;
        }
      });
    }
  }

  getProductType() {
    if (this.productTypeObservable === null) {
      this.productTypeObservable = this.dataservice.productTypeObservable.subscribe(resp => {
        this.productType = resp;
        if (this.productType !== '') {
       
          const ProductMapping = { 'P': 'Prime', 'M': 'Premier', 'ELI': 'Elite', 'APX': 'Apex' };
          this.productTypeName = ProductMapping[this.productType];
          this.getAccountList();
        }
      });
    }
  }

  getAccountList() {
    this.route.queryParams.subscribe(params => {
      this.csId = params.csId;
      this.queryParameters = params;
    });

    if (this.accountListObservable === null) {
      this.accountListObservable = this.dataservice.accountListObservable.subscribe(resp => {
        if (resp && resp.length > 0) {
          if (this.productType === 'P' || this.productType === 'M' || this.productType === 'ELI' || this.productType === 'APX' || this.productType === 'PRE') {
         
            const sortingIndex = { 'P': 0, 'M': 1, 'ELI': 2, 'APX': 3 };
  
            let is1sttPrime, is1stPrem, is1stElite, is1stApex = false;
            this.accountsList = resp.reduce((filtered, ele) => {
              const obj = Object.assign({}, ele);

              if (obj.productType === 'P' || obj.productType === 'M' || obj.productType === 'ELI' || obj.productType === 'APX' || obj.productType === 'PRE') {
                obj.balance = CommonUtility.getConvertedAmount(obj.balance);
                obj.availableBalance = CommonUtility.getConvertedAmount(obj.availableBalance);
                obj.spendableBalance = CommonUtility.getConvertedAmount(obj.spendableBalance);
                if (obj.internalDetails) {

                  const bankAccountNumber = obj.internalDetails.bankAccountNumber ?
                    obj.internalDetails.bankAccountNumber.split('/')[0] : '';
                  obj.bankLastFour = bankAccountNumber ? `- ${(bankAccountNumber + '').substr(bankAccountNumber.length - 4)}` : '';
                  if(CommonUtility.isEmptyObj(obj.internalDetails)){
               
                    obj.bankAccountNumber = ``;
                  }else{
                    obj.bankAccountNumber = `- ${bankAccountNumber}`;
       
                    
                  }
                  
                }
                if (!is1sttPrime && obj.productType === 'P') {
                  is1sttPrime = true;
                  obj.groupName = 'Prime';
                }
                if (!is1stPrem && obj.productType === 'M') {
                  is1stPrem = true;
                  obj.groupName = 'Premier';
                }
                if (!is1stElite && obj.productType === 'ELI') {
                  is1stElite = true;
                  obj.groupName = 'Elite';
                }
                if (!is1stApex && obj.productType === 'APX') {
                  is1stApex = true;
                  obj.groupName = 'Apex';
                }
                obj.sortOrder = sortingIndex[obj.productType];
                filtered.push(obj);
              }
              if (this.mainAccount && (this.mainAccount.id === obj.id)) {
                this.mainAccount = Object.assign({}, obj);
                this.accountId = this.mainAccount.id;
                if(CommonUtility.isEmptyObj(obj.internalDetails)){
                  this.eventOnBankTransaction.next(false);
                }else{
                  this.eventOnBankTransaction.next(true);
                }
                this.dataservice.setMainAccountObservable(this.mainAccount);
                this.checkRefresButtonVisiblilty();
              }
              return filtered;
            }, []);
            this.accountsList.sort((a, b) => {
              return a.sortOrder - b.sortOrder;
            });
          }
        }
      });
    }
  }

  checkRefresButtonVisiblilty() {
    if (this.mainAccount.metadata) {
      this.mainAccount.metadata = JSON.parse(this.mainAccount.metadata);
      if (this.mainAccount.metadata.refreshAccount === 'show') {
        this.showRefreshBtn = true;
      } else {
        this.showRefreshBtn = false;
      }
    }
  }

  getParty() {
    this.spinner.show();
    this.service.partyGet(this.csId).subscribe((resp: any) => {

      if (resp.status === 200) {
        this.title = resp.body.otherDetails.name;
  
        let bankData = resp.body.banks.filter((_:any)=> _.id == resp.body.bankId)
        this.bank_name=bankData?.[0]?.['name']
        console.log(this.bank_name)

        if (resp && resp.body.metadata) {
          this.bankList = [].concat(JSON.parse(resp.body.metadata).bankList);
          if (this.bankList && this.bankList.length > 0) {
            if (this.selectedAccount) { this.getBankDetail(); } else { this.selectedBank = this.bankList[0]; }
            this.bankId = this.selectedAccount ? this.selectedAccount.bankId : this.selectedBank.id;
            this.dataservice.setBankDetail(this.selectedBank);
            this.getAccount();
          }
        } else {
          this.bankId = resp.body.bankId;
         
          this.restService.getBank(this.bankId).subscribe(bank => {
            this.selectedBank=bank.body;
            this.dataservice.setBankDetail(this.selectedBank);
            this.getAccount();
          });
        }
      }
    });
  }

  getAccount() {
    const query = {
      "partyId": +this.csId,
      "metadata": true,
      "extraData": "all",
      'bankId':Number(this.bankId)
    }

    this.spinner.show();
    this.service.getCombindAccounts(query).subscribe((res: any) => {
      if (res.status === 200) {
        this.listLength = res.body.data.length;
        if (this.listLength !== 0) {
     
          this.setAccountDetail(res);
        } else {

          this.dataservice.setAccountDetail({});
        }
         this.spinner.hide();
      }
    });
  }

  setAccountDetail(resp) {
    let accountDetail = [];
    let respToReturn = {};
    accountDetail = resp.body.data.filter(ele => {
      return ele.productType === 'P' &&
        ele.internalDetails &&
        ele.internalDetails.mainSubAccount === 'M';
    });

    if (accountDetail.length === 0) {
      accountDetail = resp.body.data.filter(ele => {
        return ele.productType === 'M' &&
          ele.internalDetails &&
          ele.internalDetails.mainSubAccount === 'M';
      });
    }
    if (accountDetail.length === 0) {
      accountDetail = resp.body.data.filter(ele => {
        return ele.productType === 'P';
      });
    }
    if (accountDetail.length === 0) {
      accountDetail = resp.body.data.filter(ele => {
        return ele.productType === 'M';
      });
    }
    if (accountDetail.length === 0) {
      accountDetail = resp.body.data.filter(ele => {
        return ele;
      });
    }
    if (accountDetail.length > 0) {
 
      if (false) {
        if (this.obsAccountId === null) {
          this.obsAccountId = this.dataservice.obsAccountId.subscribe((res: any) => {
            const accTemp = resp.body.data.filter(ele => {
              if (ele.id === Number(res)) {
                return ele;
              }              
            });
            this.accountId = accTemp[0].id;
            this.setDashboardTiles();
            this.dataservice.setProductType(accTemp[0].productType);
            this.dataservice.setAccountDetail(Object.assign({}, accTemp[0]));
            this.dataservice.setAccountList([].concat(resp.body.data));
       
            respToReturn = Object.assign({}, accTemp[0]);
          });
        }
      } else {
        this.accountId = (this.back)?+sessionStorage.getItem('acnId'):accountDetail[0].id;
        sessionStorage.setItem('acnId',this.accountId.toString());
        this.setDashboardTiles();
       this.dataservice.setProductType(accountDetail[0].productType);
       this.dataservice.setAccountDetail(Object.assign({}, accountDetail[0]));
        this.dataservice.setAccountList([].concat(resp.body.data));
   

        respToReturn = Object.assign({}, accountDetail[0]);
      }
    }

    return respToReturn;
  }


  accountClick(account) {
    if (this.mainAccount.id !== account.id) {
      this.mainAccount = Object.assign({}, account);
      this.accountId = this.mainAccount.id;
      if(account.bankAccountNumber !== ''){
        this.eventOnBankTransaction.next(true);
      }else{
        this.eventOnBankTransaction.next(false);
      }
      sessionStorage.setItem('acnId',this.accountId.toString());
      this.setDashboardTiles();
      this.dataservice.setAccountDetail(Object.assign({}, account));
      this.dataservice.setProductType(this.mainAccount.productType);
      this.dataservice.setSelectCardHistory({});
      this.checkRefresButtonVisiblilty();

      if (this.router.url.includes('customer-details/cards-list')) {
        this.navigateToCurrentRoute('/admin/customer-details/cards-list', this.csId, this.queryParameters);
      }
    }
  }

  navigateToCurrentRoute(targetUrl, csId, queryParameters) {

    let firstName, middleName, lastName, companyName, mobileNumber, emailId, dob, ssn4, cardNumber, cardName, pan;
    if (queryParameters.firstName) { firstName = queryParameters.firstName; }
    if (queryParameters.middleName) { middleName = queryParameters.middleName; }
    if (queryParameters.lastName) { lastName = queryParameters.lastName; }
    if (queryParameters.companyName) { companyName = queryParameters.companyName; }
    if (queryParameters.mobileNumber) { mobileNumber = queryParameters.mobileNumber; }
    if (queryParameters.emailId) { emailId = queryParameters.emailId; }
    if (queryParameters.dob) { dob = queryParameters.dob; }
    if (queryParameters.ssn4) { ssn4 = queryParameters.ssn4; }
    if (queryParameters.cardNumber) { cardNumber = queryParameters.cardNumber; }
    if (queryParameters.cardName) { cardName = queryParameters.cardName; }
    if (queryParameters.pan) { pan = queryParameters.pan; }

    this.router.navigateByUrl('/admin/customer-details/edit-user', { skipLocationChange: true }).then(() =>
      this.router.navigate([targetUrl], {
        queryParams: {
          csId, firstName, middleName, lastName, companyName, mobileNumber, emailId, dob, ssn4, cardNumber, cardName, pan
        }
      })
    );
  }

  setDashboardTiles() {
  
    this.service.rewardGet(this.csId, this.bankId, this.accountId).subscribe((resp: any) => {
      const userDetail = resp.body;
      userDetail.totalBalance = userDetail.totalBalance ? CommonUtility.getConvertedAmount(userDetail.totalBalance) : 0;
      userDetail.totalAviBalance = userDetail.totalAviBalance ? CommonUtility.getConvertedAmount(userDetail.totalAviBalance) : 0;
      if (userDetail.totalTxAmount) {
        userDetail.totalTxAmount = ((userDetail.totalTxAmount + '').indexOf('-') !== -1) ?
          (userDetail.totalTxAmount + '').replace('-', '') : ('-' + userDetail.totalTxAmount);
        userDetail.totalTxAmount = userDetail.totalTxAmount ? CommonUtility.getConvertedAmount(userDetail.totalTxAmount) : 0;
      } else { userDetail.totalTxAmount = 0; }
      userDetail.totalRewards = userDetail.totalRewards ? userDetail.totalRewards : 0;
      userDetail.totalSettledTx = userDetail.totalSettledTx ? userDetail.totalSettledTx : 0;
      userDetail.monthRewards = userDetail.monthRewards ? userDetail.monthRewards : 0;
      userDetail.monthRewardsCount = userDetail.monthRewardsCount ? userDetail.monthRewardsCount : 0;
      userDetail.minRewardsCount = userDetail.minRewardsCount ? userDetail.minRewardsCount : 0;
      userDetail.monthRewardsEnabled = userDetail.monthRewardsEnabled === 'undefined' ? false : userDetail.monthRewardsEnabled;
      this.userDetail = Object.assign({}, userDetail);
      this.spinner.hide();
    });
  }

  getSelfAccount() {
    if (this.accountDetailObservable === null) {
      this.accountDetailObservable = this.dataservice.accountDetailObservable.subscribe((resp: any) => {
        this.mainAccount = Object.assign({}, resp);
      });
    }
  }


  ngOnDestroy() {
    if (this.accountDetailObservable) {
      this.accountDetailObservable.unsubscribe();
    }
    if (this.productTypeObservable) {
      this.productTypeObservable.unsubscribe();
    }
    if (this.accountListObservable) {
      this.accountListObservable.unsubscribe();
    }
    if (this.obsIntrumentDetail) {
      this.obsIntrumentDetail.unsubscribe();
    }
    if (this.bankDetailObservable) {
      this.bankDetailObservable.unsubscribe();
    }
    if (this.obsAccountId) {
      this.obsAccountId.unsubscribe();
    }
  }

  refreshClick() {
    this.refreshAccount();
    this.setDashboardTiles();
  }

  refreshAccount() {
    const postData = {
      accountId: Number(this.mainAccount.id),
      bankId:this.bankId
    };

    this.spinner.show();
    this.service.getRefresh(postData).subscribe((resp: any) => {
      if(resp.body.internalDetails.lastSyncTime){
        this.mainAccount.internalDetails.lastSyncTime = resp.body.internalDetails.lastSyncTime;
      }
      this.mainAccount.balance = CommonUtility.getConvertedAmount(resp.body.balance);
      this.mainAccount.availableBalance = CommonUtility.getConvertedAmount(resp.body.availableBalance);
      this.spinner.hide();
    });
  }

  bankChange(bankObj) {
    if (this.bankId !== bankObj.id) {
      setTimeout(() => {
        this.selectedBank = bankObj;
        this.bankId = bankObj.id;
        this.dataservice.setBankDetail(this.selectedBank);
        this.selectedAccount = {};
        this.getAccount();
      }, 300);
    }
  }
}
