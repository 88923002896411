/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { CommonUtility } from 'src/app/utility/common.utility';
import { AuthService } from 'src/app/services/auth.service';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./../login/login.component.scss']
})
export class SetPasswordComponent implements OnInit, AfterViewInit {

  submitted = false;
  setPassForm: FormGroup;
  username = '';
  userid = null;
  hide = false;
  hide1 = false;

  constructor(
    private customeSnakbar:CustomSnakbarComponent,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private auth: AuthService
  ) {
    this.setPassForm = this.formBuilder.group({
      NewPass: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^_&*-]).{8,}$')]],
      ConfirmPass: ['', [Validators.required]]
    }, {
      validator: this.checkPasswords
    });
  }

  ngOnInit() {
    this.username = this.service.getUserName().trim();
   this.userid = Number(this.service.getUserId());
    
  }
  checkPasswords(frm: FormGroup) {
    const pass = frm.controls.NewPass.value;
    const confirmPass = frm.controls.ConfirmPass.value;
    return pass === confirmPass ? null : { misMatch: true };
  }

  ngAfterViewInit() {
    this.auth.setExernalAuth('false');
    this.auth.getAppMode().removeItem('username');
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.setPassForm.invalid) {
      return;
    }

    const newPass = {
      newPassword: this.setPassForm.value.NewPass
    };

    this.spinner.show();
    this.service.adminUpdateUser(newPass, this.userid).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.customeSnakbar.openCustomSnakbar('Updated Successfully', '', 'success');
        this.auth.setUsername(resp.body.username);

        // Profile Required
        const profileRequired = this.auth.getProfileRequired();
        const nextCode = CommonUtility.getNextRouteCode(profileRequired, 'PWD');
        if (nextCode !== '') {
          this.auth.setExernalAuth('true');
        } else {
          this.auth.deleteProfileRequired();
        }
        const nextRoute = CommonUtility.getFirstLoginRoute(nextCode);
        this.router.navigate([nextRoute]);
      }
    });
  }

}
