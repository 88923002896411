<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mat-elevation-z8 mainCard">
  <mat-card-title class="sectionTitle">
    <div class="flex flex-row justify-between">
      <span class="mat-card-title"> Verify Email</span>
      <span mat-icon-button class="closeIcon" (click)="cancelClick()">
        <mat-icon>close</mat-icon>
      </span>
    </div>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="verifyForm" (ngSubmit)="onFormSubmit()">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxFlex="100" class="box-padding mandatory">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input type="email" matInput formControlName="email" (keypress)="NotAllowSpace($event)" />
          </mat-form-field>
          <mat-error *ngIf="submitted && verifyForm.hasError('required',['email'])" class="invalid-feedback">
            Email is required
          </mat-error>
          <mat-error *ngIf="submitted && verifyForm.hasError('customEmail',['email'])" class="invalid-feedback">
            Please Enter Valid Email
          </mat-error>
        </div>
      </div>
  
      <div class="button-row gap-x-2.5 flex" fxLayoutGap="10px">
        <button mat-raised-button color="primary">Verify</button>
       <button mat-raised-button (click)="cancelClick()" type="button">Cancel</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
