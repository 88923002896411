import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskAccountNumber'
})
export class MaskAccountNumberPipe implements PipeTransform {

  transform(accountNumber: string, hide = true, visibleDigits = 4): string {
    const maskedNumbers = accountNumber.slice(0, -visibleDigits);
    const visibleNumbers = accountNumber.slice(-visibleDigits);
    return hide ? maskedNumbers.replace(/./g, '*') + visibleNumbers : accountNumber;
  }

}
