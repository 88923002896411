/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AddTransactionLimitDialogComponent } from '../add-transaction-limit-dialog/add-transaction-limit-dialog.component';
import { UpdateTransactionLimitDialogComponent } from '../update-transaction-limit-dialog/update-transaction-limit-dialog.component';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { RemoveControlComponent } from 'src/app/shared/shared-component/remove-control/remove-control.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonUtility } from 'src/app/utility/common.utility';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-transaction-limit',
  templateUrl: './transaction-limit.component.html',
  styleUrls: ['./transaction-limit.component.scss']
})
export class TransactionLimitComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup: any;
  policyId: number;

  dailyData = [] as any;
  weeklyData = [] as any;
  monthlyData = [] as any;

  merchantType = {} as any;
  transactionLimit = {} as any;
  transaction = {} as any;
  control = 'txnlimit';

  obsMerchantTypedata = null;

  paginationConfig = {
    CurrentPage: 1,
    ItemsPerPage: 5,
    TotalItems: 0,
    Offset: 0
  };

  constructor(
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private service: RestServicesService,
    private route: ActivatedRoute,
    private dataservice: DataService,
    private customeSnakbar:CustomSnakbarComponent,
    private commonApi: CommonUtility
  ) { }

  ngOnInit() {
    if (this.obsMerchantTypedata === null) {
      this.obsMerchantTypedata = this.dataservice.obsMerchantTypedata.subscribe((resp: any) => {
        if (resp.length > 0) {
          resp.forEach((v: any, k: any) => {
            this.merchantType[v.code] = v.description;
          });
        } else {
          this.commonApi.GetMerchantTypeAsyc();
        }
      });
    }

    this.GetDailyData();
    this.GetActions();
  }

  ngOnDestroy() {
    if (this.obsMerchantTypedata) {
      this.obsMerchantTypedata.unsubscribe();
    }
  }

  dailyPageChanged(page: number) {
    this.paginationConfig.CurrentPage = page;
    this.paginationConfig.Offset = (this.paginationConfig.CurrentPage - 1) * this.paginationConfig.ItemsPerPage;
    this.GetDailyData();
  }
  weeklyPageChanged(page: number) {
    this.paginationConfig.CurrentPage = page;
    this.paginationConfig.Offset = (this.paginationConfig.CurrentPage - 1) * this.paginationConfig.ItemsPerPage;
    this.GetWeeklyData();
  }
  monthlyPageChanged(page: number) {
    this.paginationConfig.CurrentPage = page;
    this.paginationConfig.Offset = (this.paginationConfig.CurrentPage - 1) * this.paginationConfig.ItemsPerPage;
    this.GetMonthlyData();
  }

  OnTabClick(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.GetDailyData();
    } else if (event.index === 1) {
      this.GetWeeklyData();
    } else if (event.index === 2) {
      this.GetMonthlyData();
    }
  }
  GetDailyData() {
    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
    });

    this.spinner.show();

    this.service.getDailyTransactionLimits(this.policyId, 'PP').subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200) {
        this.paginationConfig.TotalItems = res.body.data.length;
        this.dailyData = res.body.data;
      }
    });
  }
  GetWeeklyData() {
    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
    });

    this.spinner.show();

    this.service.getWeeklyTransactionLimits(this.policyId, 'PP').subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200) {
        this.paginationConfig.TotalItems = res.body.data.length;
        this.weeklyData = res.body.data;
      }
    });
  }
  GetMonthlyData() {
    this.route.queryParams.subscribe(params => {
      this.policyId = params.policyId;
    });

    this.spinner.show();

    this.service.getMonthlyTransactionLimits(this.policyId, 'PP').subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200) {
        this.paginationConfig.TotalItems = res.body.data.length;
        this.monthlyData = res.body.data;
      }
    });
  }

  GetActions() {
    const payload = {
      paths: ['programpolicy.control']
    };
    this.spinner.show();
    this.service.getActions(payload).subscribe((menuResp: any) => {
      this.spinner.hide();
      if (menuResp.status === 200) {
        if (menuResp.body.allowedPaths[0].subPath) {
          const newPolicy = {} as any;
          menuResp.body.allowedPaths[0].subPath.forEach((element: any) => {
            if (element.action !== null) {
              const tempKey = element.path.split('.')[1];
              newPolicy[tempKey] = element.action;
              newPolicy.control.split(',').forEach((data: any, index: any) => {
                this.transaction['policyOption' + data] = data;
              });
            }
          });
        }
      }
    });
  }

  addTransactionLimitDialog(): void {
    this.spinner.show();
    let dataSource = {} as any;
    switch (this.tabGroup.selectedIndex) {
      case 0:
        dataSource = this.dailyData;
        break;

      case 1:
        dataSource = this.weeklyData;
        break;

      case 2:
        dataSource = this.monthlyData;
        break;

      default:
        break;
    }
    dataSource.currentTab = this.tabGroup.selectedIndex;

    const dialogRef = this.dialog.open(AddTransactionLimitDialogComponent, {
      width: '50%',
      data: dataSource
    });
    dialogRef.afterClosed().subscribe((currentTab) => {
      switch (currentTab) {
        case 0:
          this.GetDailyData();
          break;

        case 1:
          this.GetWeeklyData();
          break;

        case 2:
          this.GetMonthlyData();
          break;

        default:
          break;
      }
    });
  }
  updateTransactionLimitDialog(data: any): void {
    const dialogRef = this.dialog.open(UpdateTransactionLimitDialogComponent, {
      width: '50%',
      data: { source: Object.assign({}, data), currentTab: this.tabGroup.selectedIndex }
    });
    dialogRef.afterClosed().subscribe((currentTab) => {
      switch (currentTab) {
        case 0:
          this.GetDailyData();
          break;

        case 1:
          this.GetWeeklyData();
          break;

        case 2:
          this.GetMonthlyData();
          break;

        default:
          break;
      }
    });
  }
  removeTransactionLimitDialog(data: any): void {
    this.transactionLimit = {
      entityType: 'PP',
      entityId: Number(this.policyId),
      limitType: data.limitType,
      mccType: data.mccType
    };

    const message = 'Are you sure you want to remove this Transaction Limit Rule ?';
    const okFlag = 'Remove';

    const dialogRef = this.dialog.open(RemoveControlComponent, {
      data: { message, currentTab: this.tabGroup.selectedIndex, okFlag}
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        switch (this.tabGroup.selectedIndex) {
          case 0:
            this.spinner.show();
            this.service.deleteControl(this.control, this.transactionLimit).subscribe((resp: any) => {
              this.spinner.hide();
              if (resp.status === 200) {
                this.customeSnakbar.openCustomSnakbar('Removed successfully', '', 'success');
                this.GetDailyData();
              }
            });
            break;

          case 1:
            this.spinner.show();
            this.service.deleteControl(this.control, this.transactionLimit).subscribe((resp: any) => {
              this.spinner.hide();
              if (resp.status === 200) {
                this.customeSnakbar.openCustomSnakbar('Removed successfully', '', 'success');
                this.GetWeeklyData();
              }
            });
            break;

          case 2:
            this.spinner.show();
            this.service.deleteControl(this.control, this.transactionLimit).subscribe((resp: any) => {
              this.spinner.hide();
              if (resp.status === 200) {
                this.customeSnakbar.openCustomSnakbar('Removed successfully', '', 'success');
                this.GetMonthlyData();
              }
            });
            break;

          default:
            break;
        }
      }
    });
  }
}
