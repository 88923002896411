<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<div class="statisticsTilesList">
  <div class="statisticsTilesListInner">
    <div class="panel statisticsTiles accountTiles shadow-30" *ngIf="(isPrimeUser | async) && listLength!==0">
      <div class="title">
        <h6>{{productTypeName}}
          <span class="text-blue">{{mainAccount.name}} {{mainAccount.bankAccountNumber}}</span>
        </h6>
        <span class="tools" *ngIf="showRefreshBtn">
          <a href="javascript:void(0)" (click)="refreshClick()"> <i class="m-refresh"></i> Refresh</a>
        </span>
      </div>
      <div class="graphData flex flex-row  items-center w-[100%]">
        <div class="flex w-[75%] gap-5">
          <div class="group">
            <p class="graph-color-1">${{mainAccount.balance | number:'.2'}}</p>
            <label>Account Balance</label>
          </div>
          <div class="group">
            <p class="graph-color-2">${{mainAccount.availableBalance | number:'.2'}}</p>
            <label>Available Balance</label>
          </div>
        </div>
        <div class="group disabled flex flex-row justify-end float-right w-[25%]"
          *ngIf="mainAccount.internalDetails.lastSyncTime">
          <div>
            <small>
              {{mainAccount.internalDetails.lastSyncTime | date:'MM/dd/yyyy'}}
              {{mainAccount.internalDetails.lastSyncTime | date:'hh:mm a'}}
            </small>
            <label class="text-right">Last Sync</label>
          </div>
        </div>
      </div>
      <div class="accountDropDwon self-center align-middle">
        <button class="self-center" mat-button [matMenuTriggerFor]="accountList"> <i class="m-angle-down"></i> </button>
        <mat-menu #accountList="matMenu" xPosition="before">
          <div *ngFor="let account of accountsList">
            <span class="menu-label">{{account.groupName}}</span>
            <button mat-menu-item (click)="accountClick(account)">
              {{account.label}}
            </button>
          </div>
        </mat-menu>
      </div>
    </div>

    <div class="panel statisticsTiles accountTiles shadow-30" *ngIf="!(isPrimeUser | async) && listLength!==0">
      <div class="title">
        <h6 *ngIf="(isPrimeUser | async)">My Cards at {{userDetail.bankName}}</h6>
        <h6 *ngIf="!(isPrimeUser | async)">My Card at {{userDetail.bankName}}</h6>
      </div>
      <div class="graphData flex flex-row justify-start items-center gap-2">

        <div class="group">
          <p class="graph-color-1">${{userDetail.totalBalance | number:'.2'}}</p>
          <label>Account Balance</label>
        </div>
        <div class="group">
          <p class="graph-color-2">${{userDetail.totalAviBalance | number:'.2'}}</p>
          <label>Available Balance</label>
        </div>
      </div>
    </div>

    <div class="panel statisticsTiles shadow-30" *ngIf="!(isPrimeUser | async) && listLength!==0">
      <div class="title">
        <h6>Transactions</h6>
      </div>

      <div class="graphData flex flex-row justify-start items-center gap-2">
        <div class="group">
          <p class="graph-color-1">${{userDetail.totalTxAmount | number:'.2'}}</p>
          <label>Total Spend</label>
        </div>
        <div class="group">
          <p class="graph-color-2">{{userDetail.totalSettledTx | number}}</p>
          <label>Settled</label>
        </div>
      </div>
    </div>

    <div class="panel statisticsTiles accountTiles shadow-30" *ngIf="listLength!==0">
      <div class="title">
        <h6>MOCA Coins
        </h6>
      </div>
      <div class="graphData flex flex-row justify-start items-center gap-4">
        <div class="graph">
          <img src="../../../../../../assets/images/40.png" alt="Moca Coin" />
        </div>
        <div class="group">
          <p class="graph-color-4">{{userDetail.totalRewards | number}}</p>
          <label>Total Coins</label>
        </div>
        <div class="group" [ngClass]="!userDetail.monthRewardsEnabled ? 'disabled' : ''">
          <p [ngClass]="!userDetail.monthRewardsEnabled ? '' : 'graph-color-4'">{{userDetail.monthRewards | number}}</p>
          <label>Monthly Coins</label>
        </div>
        <div class="group" [ngClass]="!userDetail.monthRewardsEnabled ? 'disabled' : ''">
          <p [ngClass]="!userDetail.monthRewardsEnabled ? '' : 'graph-color-4'">{{userDetail.monthRewardsCount}} /
            {{userDetail.minRewardsCount}}</p>
          <label>Qualifying Transactions</label>
        </div>
      </div>
    </div>

    <div fxFlex class="bankParty">
      <h5 *ngIf="bank_name">{{bank_name}}</h5>
      <h5>{{title}}</h5>
      <div class="bankDropDown" *ngIf="bankList.length > 1">
        <label class="mobileMenu">Select Bank</label>
        <button class="bankOptions" mat-button [matMenuTriggerFor]="bankOptions">{{selectedBank.name}}<i
            class="m-angle-down" style="padding-left: 10px;"></i></button>
        <mat-menu #bankOptions="matMenu" [overlapTrigger]="false">
          <button mat-menu-item *ngFor="let bank of bankList" (click)="bankChange(bank)">{{bank.name}}</button>
        </mat-menu>
      </div>
    </div>

  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.2)" size="medium" color="#fff" type="ball-beat" [fullScreen]="true">
  <p style="color: white"> Please wait... </p>
</ngx-spinner>