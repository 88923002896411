<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mat-elevation-z8 mainCard">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" class="sectionTitle">
    <span>Transaction Limit Controls</span>
    <a mat-raised-button color="primary" pull-right (click)="addTransactionLimitDialog()"
      *ngIf="transaction['policyOptionadd']==='add'"> Add </a>
  </mat-card-title>
  <mat-card-content class="sectionContent">
    <mat-tab-group #tabGroup mat-stretch-tabs class="example-stretched-tabs tabs-style"
      (selectedTabChange)="OnTabClick($event)">
      <mat-tab label="Day">
        <div class="limitTabs">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="totalSpend programHead"
            *ngIf="dailyData.length!==0">
            <div fxLayout="column" fxFlex="45" class="displayData">
              <label>Merchant Type</label>
            </div>
            <div fxLayout="column" fxFlex="20" class="displayData">
              <label>Amount</label>
            </div>
            <div fxLayout="column" fxFlex="25" class="displayData">
              <label>Count</label>
            </div>
            <div fxLayout="column" fxFlex="10" class="displayData" *ngIf="transaction['policyOptionedit']==='edit'">
              <label></label>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start" class="programTable"
            *ngFor="let s of dailyData | paginate: {totalItems: paginationConfig.TotalItems, itemsPerPage: paginationConfig.ItemsPerPage, currentPage: paginationConfig.CurrentPage}; index as index">
            <div fxLayout="column" fxFlex="45" class="displayData programContent">
              <span>{{merchantType[s.mccType]}}</span>
            </div>
            <div fxLayout="column" fxFlex="20" class="displayData programContent">
              <span *ngIf="s.amount">$ {{s.amount | number:'.2'}}</span>
            </div>
            <div fxLayout="column" fxFlex="25" class="displayData programContent">
              <span>{{s.count}}</span>
            </div>
            <div fxLayout="column" fxFlex="10" class="displayData programContent" *ngIf="transaction['policyOptionedit']==='edit'">
              <span>
                <button class="threeDot" mat-flat-button [matMenuTriggerFor]="dailylimitmenu"><i
                    class="m-ellipsis-v"></i></button>
                <mat-menu #dailylimitmenu="matMenu">
                  <a mat-menu-item (click)="removeTransactionLimitDialog(s, index)">Remove</a>
                  <a mat-menu-item (click)="updateTransactionLimitDialog(s, index)">Edit</a>
                </mat-menu>
              </span>
            </div>
          </div>
          <div class="empty-data" *ngIf="dailyData.length===0">No Data Available</div>
          <pagination-controls class="common-pagination" (pageChange)="dailyPageChanged($event)" autoHide="true">
          </pagination-controls>
        </div>
      </mat-tab>
      <mat-tab label="Week">
        <div class="limitTabs">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="totalSpend programHead"
            *ngIf="weeklyData.length!==0">
            <div fxLayout="column" fxFlex="45" class="displayData">
              <label>Merchant Type</label>
            </div>
            <div fxLayout="column" fxFlex="20" class="displayData">
              <label>Amount</label>
            </div>
            <div fxLayout="column" fxFlex="25" class="displayData">
              <label>Count</label>
            </div>
            <div fxLayout="column" fxFlex="10" class="displayData" *ngIf="transaction['policyOptionedit']==='edit'">
              <label></label>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start" class="programTable"
            *ngFor="let s of weeklyData | paginate: {totalItems: paginationConfig.TotalItems, itemsPerPage: paginationConfig.ItemsPerPage, currentPage: paginationConfig.CurrentPage}; index as index">
            <div fxLayout="column" fxFlex="45" class="displayData programContent">
              <span>{{merchantType[s.mccType]}}</span>
            </div>
            <div fxLayout="column" fxFlex="20" class="displayData programContent">
              <span *ngIf="s.amount">$ {{s.amount | number:'.2'}}</span>
            </div>
            <div fxLayout="column" fxFlex="25" class="displayData programContent">
              <span>{{s.count}}</span>
            </div>
            <div fxLayout="column" fxFlex="10" class="displayData programContent" *ngIf="transaction['policyOptionedit']==='edit'">
              <span>
                <button class="threeDot" mat-flat-button [matMenuTriggerFor]="weeklimitmenu"><i
                    class="m-ellipsis-v"></i></button>
                <mat-menu #weeklimitmenu="matMenu">
                  <a mat-menu-item (click)="removeTransactionLimitDialog(s, index)">Delete</a>
                  <a mat-menu-item (click)="updateTransactionLimitDialog(s, index)">Edit</a>
                </mat-menu>
              </span>
            </div>
          </div>
          <div class="empty-data" *ngIf="weeklyData.length===0">No Data Available</div>
          <pagination-controls class="common-pagination" (pageChange)="weeklyPageChanged($event)" autoHide="true">
          </pagination-controls>
        </div>
      </mat-tab>
      <mat-tab label="Month">
        <div class="limitTabs">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="totalSpend programHead"
            *ngIf="monthlyData.length!==0">
            <div fxLayout="column" fxFlex="45" class="displayData">
              <label>Merchant Type</label>
            </div>
            <div fxLayout="column" fxFlex="20" class="displayData">
              <label>Amount</label>
            </div>
            <div fxLayout="column" fxFlex="25" class="displayData">
              <label>Count</label>
            </div>
            <div fxLayout="column" fxFlex="10" class="displayData" *ngIf="transaction['policyOptionedit']==='edit'">
              <label></label>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start" class="programTable"
            *ngFor="let s of monthlyData | paginate: {totalItems: paginationConfig.TotalItems, itemsPerPage: paginationConfig.ItemsPerPage, currentPage: paginationConfig.CurrentPage}; index as index">
            <div fxLayout="column" fxFlex="45" class="displayData programContent">
              <span>{{merchantType[s.mccType]}}</span>
            </div>
            <div fxLayout="column" fxFlex="20" class="displayData programContent">
              <span *ngIf="s.amount">$ {{s.amount | number:'.2'}}</span>
            </div>
            <div fxLayout="column" fxFlex="25" class="displayData programContent">
              <span>{{s.count}}</span>
            </div>
            <div fxLayout="column" fxFlex="10" class="displayData programContent" *ngIf="transaction['policyOptionedit']==='edit'">
              <span>
                <button class="threeDot" mat-flat-button [matMenuTriggerFor]="monthlimitmenu"><i
                    class="m-ellipsis-v"></i></button>
                <mat-menu #monthlimitmenu="matMenu">
                  <a mat-menu-item (click)="removeTransactionLimitDialog(s, index)">Delete</a>
                  <a mat-menu-item (click)="updateTransactionLimitDialog(s, index)">Edit</a>
                </mat-menu>
              </span>
            </div>
          </div>
          <div class="empty-data" *ngIf="monthlyData.length===0">No Data Available</div>
          <pagination-controls class="common-pagination" (pageChange)="monthlyPageChanged($event)" autoHide="true">
          </pagination-controls>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
