import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-success-error-template',
  templateUrl: './success-error-template.component.html',
  styleUrls: ['./success-error-template.component.scss']
})
export class SuccessErrorTemplateComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data:any,private snakBar:MatSnackBar) { }

  ngOnInit(): void {
  }

  dismissSnackBar(){
   this.snakBar.dismiss();
  }

}
