<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<div fxLayout="row">
  <mat-card class="aside mat-elevation-z8" fxFlex="100">
    <p class="headings">Account Info</p>
    <div class="account-info" fxLayout="row" fxLayoutAlign="start center">
      <span class="user-image"><i class="material-icons"> people </i></span>
      <div fxLayout="column" fxLayoutAlign="start start">
        <span class="name">Danise</span>
        <span class="account">XXXX XXXX 8437</span>
      </div>
    </div>
    <p class="headings">Actions</p>
    <ul class="left-menus">
      <li *ngFor="let panel of panels">
        <a [routerLink]="[panel.routeLink]" [queryParams]="panel.queryParams"
          routerLinkActive="active-link">{{panel.title}}</a>
      </li>
    </ul>
  </mat-card>
</div>
