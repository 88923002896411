import { Component, ViewChild, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-search-table',
  templateUrl: './search-table.component.html',
  styleUrls: ['./search-table.component.scss']
})
export class SearchTableComponent implements OnChanges {

  @Input('columns') allColumns: any[] = [];
  @Input('rows') allRows: Array<{}> = [];
  @Input('page-size') pageSize = 5;
  @Input('filter-mode') filterMode = 'table';
  @Input('sort-mode') sortMode = 'table';
  @Input('pagination-mode') paginationMode = 'table';
  @Input('table-length') tableLength = 10;
  @Input('sortable-columns') sortableColumns: string[] = [];
  @Input('isLoading') isLoading = false;
  @Input('showActionColumn') showActionColumn = false;
  @Input('showFilter') showFilter = true;
  @Input('showColumnFilter') showColumnFilter = true;
  @Input('showPaginator') showPaginator = true;
  @Input('currentPage') currentPage: any;
  @Output('onFilter') onFilter = new EventEmitter();
  @Output('onSort') onSort = new EventEmitter();
  @Output('onPageChange') onPageChange = new EventEmitter();
  @Output('onMenuItemClicked') onMenuItemClicked = new EventEmitter();
  @Output('onOpenActionClicked') onOpenActionClicked = new EventEmitter();

  @Input('pageOptions') pageOptions: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageEvent: any;
  displayedColumns: string[] = [];
  dataSource;
  selection;
  filterText = '';

  pageSep = 0;

  constructor() { }

  columnsDropDown = new UntypedFormControl();


  ngOnChanges() {
    this.initializeTable();
  }
  ngAfterViewInit() {
    if (this.paginationMode !== 'server') { this.dataSource.paginator = this.paginator; }
  }
  initializeTable() {
    if (this.allRows.length > 0 || this.allRows.length === 0) {
      this.pageSep = this.pageSep === 0 ? this.pageSize : this.pageSep;
      this.dataSource = new MatTableDataSource(this.allRows);
      this.selection = new SelectionModel(true, []);
      if (this.paginationMode !== 'server') { this.dataSource.paginator = this.paginator; }
      this.dataSource.sort = this.sort;
      this.displayedColumns = this.allColumns.map(col => col.id);
      if (this.showActionColumn) { this.displayedColumns.push('action'); }
  
      if (this.pageOptions.length === 0) { this.pageOptions = [].concat(this.setRowsPerPage(this.tableLength)); }
      if (this.sortMode === 'server') { this.dataSource.sortingDataAccessor = (item, property) => { }; }
    }
  }
  sortChange(event) {
    if (this.sortMode === 'server') { this.onSort.emit(event); }
  }
  applyFilter(filterValue: string) {
    if (this.filterMode === 'server') {
      this.onFilter.emit(this.filterText.trim().toLowerCase());
    } else {
      this.dataSource.filter = this.filterText.trim().toLowerCase();
    }
  }

  setRowsPerPage(rowsLength: number) {
    let options = rowsLength / this.pageSep;
    options = options + 1;
    const pageOptions = [];
    for (let i = 1; i < options; i++) {
      pageOptions.push(i * this.pageSep);
    }
    return pageOptions;
  }
  isSortingDisabled(column) {
    if (this.sortableColumns.indexOf(column) !== -1) {
      return false;
    } else { return true; }
  }
  getPaginatorData(event) {
    if (this.paginationMode === 'server') { this.onPageChange.emit(event); }
  }

  changeSelect($event) {
    const colArry = $event.value;
    this.displayedColumns = [].concat(colArry);
    if (this.showActionColumn) { this.displayedColumns.push('action'); }
  }

  menuItemClicked(action, row) {
    const res = { action, row };
    this.onMenuItemClicked.emit(res);
  }

  openActionClicked(action, row) {
    const res = { action, row };
    this.onOpenActionClicked.emit(res);
  }

  showActionLink(col, row) {
    let isVisible = false;
    if (col.isAction) {
      isVisible = true;
      if (row.conditionalActions !== undefined) {
        isVisible = (row.conditionalActions[col.id] !== undefined) ? row.conditionalActions[col.id] : isVisible;
      }
    }
    return isVisible;
  }

}
