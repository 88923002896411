<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card-title>
  <div class="flex flex-row justify-between px-[20px] pt-3">
    <span class="font-bold">Store Loation</span>
  <button class="closeIcon" (click)="onCancellick()" >
    <mat-icon>close</mat-icon>
  </button>
  </div>
</mat-card-title>
<mat-dialog-content  class="map-container">
  <div *ngIf="apiLoaded | async">
    <google-map [options]="options" [height]="500" [width]="700" class="gMap">
      <map-marker *ngFor="let markerPosition of markerPositions"
                  [position]="markerPosition"
                  [options]="markerOptions"></map-marker>
    </google-map>
  </div>
</mat-dialog-content>
