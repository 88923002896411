<form [formGroup]="searchForm" class="example-form">
  <mat-form-field appearance="fill">
    <mat-label>Enter Bank Name</mat-label>
    <mat-icon matPrefix class="text-[#2274a5]">search</mat-icon>
    <input type="text" matInput formControlName="searchCtrl"/>
    <mat-spinner *ngIf="!isLoading" diameter="20" matSuffix></mat-spinner>
  </mat-form-field>
</form>
<div class="mat-dialog-content">
  <cdk-virtual-scroll-viewport class="mt-1" itemSize="{{filteredBanksLength | async}}">
    <cdk-virtual-scroll-viewport itemSize="{{filteredBanksLength | async}}">
      <mat-selection-list [multiple]="false">
        <mat-list-option class="mat-list__elevate"  (click)="close(bank.id)" *ngFor="let bank of filteredBanks | async" [value]="bank.id">
           <img alt="mocapay" class="imgIcon" matListItemIcon  [src]="sanitizeUrl(bank.bankLogo)" data-cy="enabledBanks-logo">
          <div matListItemTitle data-cy="enabledBanks-title">{{bank.name}}</div>
        </mat-list-option>
      </mat-selection-list>
    </cdk-virtual-scroll-viewport>
  </cdk-virtual-scroll-viewport>
</div>
