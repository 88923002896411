<mat-form-field>
    <mat-label>Please choose month and year</mat-label>
    <input [min]="minDate" [max]="maxDate" matInput [matDatepicker]="dp" [formControl]="date">
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
                    [startView]="'multi-year'"
                    (monthSelected)="setMonthAndYear($event, dp)"
                    panelClass="example-month-picker">
    </mat-datepicker>
    <mat-error *ngIf="date.touched && date.hasError('matDatepickerMin')">Min date error</mat-error>
    <mat-error *ngIf="date.touched && date.hasError('matDatepickerMax')">Max date error</mat-error>
    <mat-error *ngIf="date.touched && !date.value">Invalid input</mat-error>
  </mat-form-field>