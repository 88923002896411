<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mat-elevation-z8 mainCard">
  <mat-card-title class="sectionTitle">
    Monthly Fees
  </mat-card-title>

  <mat-card-content class="sectionContent">
    <form [formGroup]="updateMonthlyForm" (ngSubmit)="onSubmit()">
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxLayout="column" fxFlex="50" class="box-padding">
          <mat-form-field>
            <mat-label>Monthly Fees</mat-label>
            <input *ngIf="rowData !== undefined" type="text" matInput
              formControlName="MonthlyFee" [(ngModel)]="rowData.monthlyFee" />
            <input *ngIf="rowData === undefined" type="text" matInput
              formControlName="MonthlyFee" />
          </mat-form-field>
          <mat-error *ngIf="submitted && updateMonthlyForm.hasError('pattern',['MonthlyFee'])" class="invalid-feedback">
            Only number should be allowed
          </mat-error>
        </div>
        <div fxLayout="column" fxFlex="50" class="box-padding">
          <mat-form-field>
            <mat-label>Monthly Non Use Fees</mat-label>
            <input *ngIf="rowData !== undefined" type="text" matInput
              formControlName="NonUseFee"
              [(ngModel)]="rowData.monthlyNonuseFee ? rowData.monthlyNonuseFee : rowData.monthlyNonUseFee" />
            <input *ngIf="rowData === undefined" type="text" matInput
              formControlName="NonUseFee" />
          </mat-form-field>
          <mat-error *ngIf="submitted && updateMonthlyForm.hasError('pattern',['NonUseFee'])" class="invalid-feedback">
            Only number should be allowed
          </mat-error>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxFlex="50" class="box-padding">
          <mat-form-field>
            <mat-label>Monthly Non Use Days</mat-label>
            <input *ngIf="rowData !== undefined" type="text" matInput
              formControlName="NonUseDays"
              [(ngModel)]="rowData.monthlyNonuseDays ? rowData.monthlyNonuseDays : rowData.monthlyNonUseDays" />
            <input *ngIf="rowData === undefined" type="text" matInput
              formControlName="NonUseDays" />
          </mat-form-field>
          <mat-error *ngIf="submitted && updateMonthlyForm.hasError('pattern',['NonUseDays'])" class="invalid-feedback">
            Only number should be allowed
          </mat-error>
        </div>
      </div>
      <div class="button-row">
        <button mat-raised-button color="primary">Update</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
