<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mainCard">
  <mat-card-title class="sectionTitle">
    {{title}}
    <button class="closeIcon" (click)="cancelClick()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-title>

  <mat-card-content>
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [imageFile]="blobData"
      [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="16 / 9" [resizeToWidth]="0"
      [cropperMinWidth]="128" [cropperStaticWidth]="0" [cropperStaticHeight]="0" [roundCropper]="false"
      [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'" [format]="format" (imageCropped)="imageCropped($event)"
      [onlyScaleDown]="true" [style.height]="'auto'" [style.width]="'auto'" [style.maxWidth]="'500px'"
      [style.maxHeight]="'500px'">
    </image-cropper>

    <div class="button-row space-x-[10px]">
      <span *ngIf="service.getPartyType()!=='BAU'">
        <button mat-raised-button (click)="rotateRight()"> Rotate right </button>
        <button mat-raised-button (click)="uploadImage()" color="primary"
          [disabled]="updatedImgArryBuffer ? false : true">{{isNewUpload ? 'Upload' : 'Save'}} </button>
      </span>
      <button *ngIf="!isNewUpload" mat-raised-button color="primary" (click)="downloadClick()">Download</button>
    </div>
  </mat-card-content>
</mat-card>
