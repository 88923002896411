import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, delay, distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { BankDetailsExtendedWLogo } from 'src/app/models/bank.interface';
import { MenuContextualService } from 'src/app/views/report-designer/menu-contextual.service';

@Component({
  selector: 'app-enabled-banks-dialog',
  templateUrl: './enabled-banks-dialog.component.html',
  styleUrls: ['./enabled-banks-dialog.component.scss'],
  changeDetection:ChangeDetectionStrategy.Default
})
export class EnabledBanksDialogComponent {
  searchForm:FormGroup;
  filteredBanks: Observable<BankDetailsExtendedWLogo[]>;
  isLoading: boolean = false;
  @Input('banks') _bankList: BankDetailsExtendedWLogo[]
  @Output() selectItem = new EventEmitter<number>();




  constructor(
    private sanitizer: DomSanitizer,
    private fb:FormBuilder,
    private popupService:MenuContextualService,

  ) {
    this.searchForm = this.fb.group({
      searchCtrl:['']
    });
  }

  sanitizeUrl(bankLogo: SafeUrl): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(bankLogo as string);
  }

  get filteredBanksLength(): Observable<number> {
    return this.filteredBanks.pipe(
      map(list => list.length ?? 0),
    );
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes){
      this.filteredBanks = this.searchForm.get('searchCtrl').valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        tap(_ => this.isLoading = true),
        delay(300),
        tap(_ => this.isLoading = false),
        map(value => this._filter(value)),
        tap(v=>{
          this.isLoading = false;
        })
      );
    }
  }







  private _filter(value: string): BankDetailsExtendedWLogo[] {
    const filterValue = value.toLowerCase();
    return this._bankList.filter(bank => bank.name.toLowerCase().includes(filterValue));
  }

  close(id){
    this.selectItem.next(id);
  }
}
