/** ---
 Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

 Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

 @Author: Newtech Systems Development team
 @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
 **/
import {Component, OnDestroy, OnInit} from '@angular/core';
import {RestServicesService} from 'src/app/services/rest-services.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from 'src/app/app-dto/rules.dto';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import packageInfo from '../../../../package.json';
import {CommonUtility} from '../../utility/common.utility';
import {MatDialog} from '@angular/material/dialog';
import {SecurityDialogComponent} from '../../shared/shared-component/security-dialog/security-dialog.component';
import {OtpDialogComponent} from '../../shared/shared-component/otp-dialog/otp-dialog.component';
import {CustomValidators} from 'src/app/validators/custom.validators';
import {CustomSnakbarComponent} from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';
import {RecaptchaService} from '../../services/recaptcha.service';
import {mergeMap, Observable, of, Subscription} from 'rxjs';
import {UuidManagerService} from 'src/app/services/uuid-manager.service';
import {DataService} from '../../services/data.service';
import {map} from 'rxjs/operators';
import {JsEncryptWrapperService} from '../../services/js-encrypt-wrapper.service';
import {Captcha} from '../../service-interface/interface-list';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  submitted = false;
  loginForm: FormGroup;
  user: User;
  version: string = packageInfo.version;
  hide = false;
  public size: "compact" | "normal" = "normal";
  siteConfig: any = {};
  obsFlagsdata = null;
  protected captcha$: Observable<Captcha>;
  protected resolvedCaptchaToken: string | undefined | null;
  private recaptchaService$: Subscription;

  constructor(
    private service: RestServicesService,
    private uuidManagerService: UuidManagerService,
    private spinner: NgxSpinnerService,
    private customeSnakbar: CustomSnakbarComponent,
    private formBuilder: FormBuilder,
    private dataservice: DataService,
    private router: Router,
    private auth: AuthService,
    private dialog: MatDialog,
    private jsEncryptWrapperService: JsEncryptWrapperService,
    private commonApi: CommonUtility
  ) {
    this.user = {
      username: "",
      password: "",
      captchaToken: "",
    };
  }

  private get getDecryptedCaptcha$(): Observable<Captcha> {
    return of(this.siteConfig).pipe(
      mergeMap((config) =>
        of(config?.captcha).pipe(
          mergeMap((captcha) =>
            this.jsEncryptWrapperService.decryptData(captcha?.key)
          ),
          map((key) => {
            return {
              ...config?.captcha,
              key,
            } as Captcha;
          })
        )
      )
    );
  }

  ngOnDestroy(): void {
    this.recaptchaService$?.unsubscribe();
    if (this.obsFlagsdata) {
      this.obsFlagsdata.unsubscribe();
    }
  }

  getProgramPolicyFlags() {
    if (this.obsFlagsdata === null) {
      this.obsFlagsdata = this.dataservice.obsFlagsData.subscribe((data) => {
        if (!CommonUtility.isEmptyObj(data)) {
          this.siteConfig = data;
          this.captcha$ = this.getDecryptedCaptcha$;
        } else {
          setTimeout(() => {
            this.service.getConfigDetail();
            this.commonApi.GetFlagsData();
          }, 3000);
        }
      });
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      Username: ["", [Validators.required, CustomValidators.alphanumeric]],
      Password: ["", [Validators.required]],
    });

    this.dataservice.configLoadedObservable.subscribe((v) => {
      if (v) {
        this.getProgramPolicyFlags();
      } else {
        this.service.getConfigDetail();
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loginProcess();
  }

  async loginProcess() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    const uuid = await this.uuidManagerService.getUUID(
      this.loginForm.value.Username.trim()
    );

    this.user = {
      ...this.user,
      username: this.loginForm.value.Username.trim(),
      password: this.loginForm.value.Password,
      captchaToken: this.resolvedCaptchaToken,
      loginDeviceData: {
        deviceType: this.uuidManagerService.getDeviceType,
        uuid,
        applicationName: "Adminweb",
        browserType: this.uuidManagerService.getBrowser,
        applicationVersion: this.uuidManagerService.getApplicationVersion,
      },
    };

    this.spinner.show();
    this.service.login(this.user).subscribe(
      (resp: any) => {
        this.resolvedCaptchaToken = undefined;
        this.captcha$ = this.getDecryptedCaptcha$;
        if (resp.status === 200) {
          this.spinner.hide();
          this.auth.setToken(resp);

          const question = resp.body.securityQuestion;
          const message = resp.body.message;
          const validationType = resp.body.validationType;

          if (message && message !== "" && validationType === "ASK-OTP") {
            this.spinner.hide();

            const username = resp.body.username.trim();
            const message = resp.body.message;

            const dialogRef = this.dialog.open(OtpDialogComponent, {
              data: { message, username },
              disableClose: true,
              width: "700px",
            });

            dialogRef.afterClosed().subscribe((confirmed: any) => {
              if (confirmed) {
                this.callUserAndAdminParty(resp.body);
              }
            });
          } else if (
            question &&
            question !== "" &&
            validationType !== "ASK-OTP"
          ) {
            this.spinner.hide();
            const username = resp.body.username.trim();
            const question = resp.body.securityQuestion;

            const dialogRef = this.dialog.open(SecurityDialogComponent, {
              data: { question, username },
              disableClose: true,
              width: "400px",
            });

            dialogRef.afterClosed().subscribe((confirmed: any) => {
              if (confirmed) {
                this.callUserAndAdminParty(resp.body);
              }
            });
          } else {
            //this.setLocalVariables(res.body, resp.body, response.body, false);
          }
        }
        this.spinner.hide();
        this.auth.login();
      },
      (error) => {
        this.spinner.hide();
        this.resolvedCaptchaToken = undefined;
        this.captcha$ = this.getDecryptedCaptcha$;
      }
    );
  }

  callUserAndAdminParty(loginResp: any) {
    this.service.userGet(this.user.username).subscribe((res: any) => {
      if (res.status === 200) {
        this.service
          .adminPartyGet(res.body.partyId)
          .subscribe((response: any) => {
            if (response.status === 200) {
              this.auth.setData(res.body, response.body);
              this.setLocalVariables(res.body, loginResp, response.body, true);
            }
          });
      }
    });
  }

  setLocalVariables(userResp, loginResp, accountResp, showMsg) {
    if (userResp.partyId && accountResp !== undefined) {
      if (showMsg) {
        this.customeSnakbar.openCustomSnakbar(
          "Validated successfully!",
          "",
          "success"
        );
        this.spinner.hide();
      }
      if (loginResp.dataRequired && loginResp.dataRequired.trim() !== "") {
        this.auth.setExernalAuth("true");
        this.auth.setProfileRequired(loginResp.dataRequired);

        const nextCode = this.getNextRouteCode(loginResp.dataRequired);
        const nextRoute = CommonUtility.getFirstLoginRoute(nextCode);
        this.router.navigate([nextRoute]);
        this.spinner.hide();
      } else {
        this.customeSnakbar.openCustomSnakbar(
          "Admin Login Successfully",
          "",
          "success"
        );
        this.router.navigate(["/admin/dashboard"]);
        this.spinner.hide();
      }
    } else {
      this.customeSnakbar.openCustomSnakbar("Something went wrong!", "Close");
      this.spinner.hide();
    }
  }

  getNextRouteCode(profileRequired) {
    let arry = profileRequired.split(",");
    arry = arry.map((ele) => {
      return ele.trim();
    });
    return arry[0];
  }

  openSecurityDialog(userResp, loginResp, accountResp) {
    const username = userResp.username.trim();
    const question = loginResp.securityQuestion;

    const dialogRef = this.dialog.open(SecurityDialogComponent, {
      data: { question, username },
      disableClose: true,
      width: "400px",
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        this.setLocalVariables(userResp, loginResp, accountResp, true);
      }
    });
  }

  openOtpDialog(userResp, loginResp, accountResp) {
    const username = userResp.username.trim();
    const message = loginResp.message;

    const dialogRef = this.dialog.open(OtpDialogComponent, {
      data: { message, username },
      disableClose: true,
      width: "700px",
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed) {
        this.setLocalVariables(userResp, loginResp, accountResp, true);
      }
    });
  }
}
