<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<div class="loginWrapp items-center flex flex-row justify-between">
  <div class="mocaLogo justify-items-center w-1/2 order-2 flex flex-col">
    <img src="assets/images/logo_white.jpg" alt="Moca" title="MOCA" />
  </div>
  <div class="loginInfo w-1/2 flex flex-col  self-stretch justify-center">
    <form [formGroup]="setSecurityForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <h1>Set Questions</h1>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>First Question</mat-label>
          <mat-select formControlName="securityQuestion1"
            [(ngModel)]="QM.selectedQuestion1" (selectionChange)="onChangeQuestion($event,1)">
            <mat-option *ngFor="let s of listQuestion1" [value]="s.id">{{s.value}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="submitted && setSecurityForm.hasError('required',['securityQuestion1'])"
          class="invalid-feedback">
          First Question is required
        </mat-error>
      </div>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>First Answer</mat-label>
          <input [type]="hide ? 'text' : 'password'" autocomplete="off" matInput
            formControlName="securityAnswer1" />
          <mat-icon matSuffix class="eyeball" (click)="hide = !hide">{{!hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <mat-error *ngIf="submitted && setSecurityForm.hasError('required',['securityAnswer1'])"
          class="invalid-feedback">
          First Answer is required
        </mat-error>
        <mat-error *ngIf="setSecurityForm.hasError('alphanumeric',['securityAnswer1'])" class="invalid-feedback">
          Please provide valid input.
        </mat-error>
      </div>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>Second Question</mat-label>
          <mat-select formControlName="securityQuestion2"
            [(ngModel)]="QM.selectedQuestion2" (selectionChange)="onChangeQuestion($event,2)">
            <mat-option *ngFor="let s of listQuestion2" [value]="s.id">{{s.value}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="submitted && setSecurityForm.hasError('required',['securityQuestion2'])"
          class="invalid-feedback">
          Second Question is required
        </mat-error>
      </div>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>Second Answer</mat-label>
          <input [type]="hide2 ? 'text' : 'password'" autocomplete="off" matInput
            formControlName="securityAnswer2" />
          <mat-icon matSuffix class="eyeball" (click)="hide2 = !hide2">{{!hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <mat-error *ngIf="submitted && setSecurityForm.hasError('required',['securityAnswer2'])"
          class="invalid-feedback">
          Second Answer is required
        </mat-error>
        <mat-error *ngIf="setSecurityForm.hasError('alphanumeric',['securityAnswer2'])" class="invalid-feedback">
          Please provide valid input.
        </mat-error>
      </div>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>Third Question</mat-label>
          <mat-select formControlName="securityQuestion3"
            [(ngModel)]="QM.selectedQuestion3" (selectionChange)="onChangeQuestion($event,3)">
            <mat-option *ngFor="let s of listQuestion3" [value]="s.id">{{s.value}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="submitted && setSecurityForm.hasError('required',['securityQuestion3'])"
          class="invalid-feedback">
          Third Question is required
        </mat-error>
      </div>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>Third Answer</mat-label>
          <input [type]="hide3 ? 'text' : 'password'" autocomplete="off" matInput
            formControlName="securityAnswer3" />
          <mat-icon matSuffix class="eyeball" (click)="hide3 = !hide3">{{!hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <mat-error *ngIf="submitted && setSecurityForm.hasError('required',['securityAnswer3'])"
          class="invalid-feedback">
          Third Answer is required
        </mat-error>
        <mat-error *ngIf="setSecurityForm.hasError('alphanumeric',['securityAnswer3'])" class="invalid-feedback">
          Please provide valid input.
        </mat-error>
      </div>

      <div class="loginBtn flex flex-col">
        <button mat-raised-button color="primary">Set</button>
      </div>
    </form>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.3)" size = "medium" color = "#fff" type = "ball-beat" [fullScreen] = "true"><p style="color: white" > Please wait... </p></ngx-spinner>
