<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card-title>
  Add Single Transaction Value Control
  <button mat-dialog-close class="closeIcon">
    <mat-icon>close</mat-icon>
  </button>
</mat-card-title>

<mat-card-content>
  <form [formGroup]="addSingleTransactionForm" (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
        <mat-form-field>
          <mat-label>Merchant Type</mat-label>
          <mat-select formControlName="MccType">
            <mat-option *ngFor="let s of merchantType" [value]="s.code">{{s.description}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="submitted && addSingleTransactionForm.hasError('required',['MccType'])"
          class="invalid-feedback">
          MCC Type is required
        </mat-error>
      </div>
      <div fxLayout="column" fxFlex="50" class="box-padding mandatory">
        <mat-form-field>
          <mat-label>Max Amount ($)</mat-label>
          <input type="text" matInput formControlName="maxAmt" [maxLength]="25"
            [MobileNumberOnly]="true" />
        </mat-form-field>
        <mat-error *ngIf="submitted && addSingleTransactionForm.hasError('required',['maxAmt'])"
          class="invalid-feedback">
          Max Amount is required.
        </mat-error>
        <mat-error *ngIf="addSingleTransactionForm.hasError('alphanumeric',['maxAmt'])">
          Please provide valid input.
        </mat-error>
      </div>
    </div>
    <div class="button-row" fxLayoutGap="10px">
      <button mat-raised-button color="primary">Submit</button>
      <button mat-raised-button color="default" mat-dialog-close>Cancel</button>
    </div>
  </form>
</mat-card-content>
