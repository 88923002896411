import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessErrorTemplateComponent } from '../success-error-template/success-error-template.component';

@Component({
  selector: 'app-custom-snakbar',
  templateUrl: './custom-snakbar.component.html',
  styleUrls: ['./custom-snakbar.component.scss']
})
export class CustomSnakbarComponent {

  constructor(public _snakBar: MatSnackBar) { }



  public dismissSnackBar(){
    this._snakBar.dismiss();
  }

  openCustomSnakbar(message: string,errorCode?:string, cssClass: string = 'success') {

    this._snakBar.openFromComponent(SuccessErrorTemplateComponent, {
      panelClass: cssClass,
       duration: 5000, verticalPosition:'bottom',
       horizontalPosition:'center',
       data:{
        message:message,
        error_code:errorCode,
        globalCssClass:cssClass
       }
    })
  }



}


