/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { AbstractControl } from '@angular/forms';

export class CustomValidators {
    static customZip(control: AbstractControl) {
        const zipRegex = /^\d{5}(?:-\d{4})?$/;
        let isValid = false;
        const inputVal = control.value;
        if (inputVal && inputVal.length === 6) {
            const subValue = inputVal.substring(5, 6);
            isValid = (subValue.trim() === '') ? true : isNaN(subValue);
        } else if (inputVal && inputVal.replace('-', '').length === 9) {
            isValid = true;
        }
        if (zipRegex.test(control.value) || !control.value || isValid) {
            return null;
        }
        return { customZip: true };

    }

    static customSSN(control: AbstractControl) {
        const inputVal = control.value;
        if (inputVal || inputVal === '') {
            const newVal = inputVal.replace(/\D/g, '');
            if (newVal.length === 0) {
                return null;
            }
             if (newVal.length >= 9) {
                return null;
            }

        }
        return { customSSN: true };
    }

    static customMobile(control: AbstractControl) {
        const inputVal = control.value;
        if (inputVal || inputVal === '') {
            let newVal = inputVal.replace(/\D/g, '');
            if (newVal.length > 0 && newVal.charAt(0) === '1') {
                newVal = newVal.replace('1', '');
            }
            if (newVal.length === 0 || newVal.length === 10) {
                return null;
            }
        }
        return { customMobile: true };
    }

    static custName(control: AbstractControl) {

        const nameRegex = /([A-Za-z][A-Za-z\s\'\-]*)$/;
        if (nameRegex.test(control.value) || !control.value) {
            return null;
        }
        return { custName: true };

    }

    static customEmail(control: AbstractControl) {
       // const emailRegex = new RegExp('^[a-z0-9A-Z][a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\\.[a-zA-Z]{2,}$');
       //::ranjeet change it to support all printable character in email.
        const emailRegex = /^[^<>\x00-\x1F\x80-\xFF]+@[a-z0-9A-Z.-]+\.[a-zA-Z]{2,}$/;
        if (emailRegex.test(control.value) || !control.value) {
            return null;
        }
        return { customEmail: true };
    }


    static numOnly(control:AbstractControl){
        const nameRegex = /^\d\d*$/;
        if(nameRegex.test(control.value) || !control.value){
          return null;
        }
        return {numOnly:true}
      }

    static alphanumeric(control: AbstractControl) {
        const nameRegex = /^[ A-Za-z0-9,./#]*$/;
        if (nameRegex.test(control.value) || !control.value) {
            return null;
        }
        return { alphanumeric: true };
    }

    static alphanumericForFreeSearch(control: AbstractControl) {
        const nameRegex = /^[A-Za-z0-9 ,._/#@'-]*$/;
        if (nameRegex.test(control.value) || !control.value) {
            return null;
        }
        return { alphanumeric: true };
    }

    static allowPrintableChar(control:AbstractControl){
        const nameRegex = /^[^<>\x01-\x1F\x80-\xFF]*$/;
        console.log(nameRegex.test(control.value));
        if(nameRegex.test(control.value) || !control.value){
         return null
        }
        return {notPrintableChar:true}
      }
}

