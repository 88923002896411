import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCurrencyInput]',
  providers:[CurrencyPipe]
})
export class CurrencyInputDirective {

  constructor(private elementRef:ElementRef,
    private currencyPipe:CurrencyPipe) { }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    // on focus remove currency formatting
    this.elementRef.nativeElement.value = value.replace(/[^0-9.]+/g, '')
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    // on blur, add currency formatting 
    if(isNaN(value) || !value) return;
    this.elementRef.nativeElement.value = this.currencyPipe.transform(value, 'USD');
  }

}
