import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoreBaseUrlKeyModel, CoreFeatureUrlKeyModel } from '../shared/model/core';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CommonRestService {

  constructor(private configService:ConfigService,
    private authService:AuthService) { }

  setEndpointConfig(baseUrl: CoreBaseUrlKeyModel) {
    this.configService.setBaseUrl(baseUrl.baseUrl);
    this.configService.setConfig(this.buildFeatureEndPoints(baseUrl.baseUrl));
    this.authService.setEndpoints(this.buildFeatureEndPoints(baseUrl.baseUrl));
}

buildFeatureEndPoints(baseUrl: string): CoreFeatureUrlKeyModel {
  return {
    user_api_url: `${baseUrl}/${environment.user_end_point}`,
    doc_api_url: `${baseUrl}/${environment.doc_end_point}`,
    statement_api_url: `${baseUrl}/${environment.statement_end_point}`,
    account_api_url: `${baseUrl}/${environment.account_end_point}`,
    bank_api_url: `${baseUrl}/${environment.bank_end_point}`,
    report_api_url: `${baseUrl}/${environment.report_end_point}`,
    solr_api_url: `${baseUrl}/${environment.solr_end_point}`,
    gl_api_url: `${baseUrl}/${environment.gl_end_point}`,
  };
}


  public customerPaths = new BehaviorSubject<any>(null);
  setCustomerPaths(data: any) {
    this.customerPaths.next(data);
  }
  getCustomerPaths() {
    return this.customerPaths.asObservable();
  }
}
