import {Injectable, isDevMode} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import FingerprintJS, {GetResult} from '@fingerprintjs/fingerprintjs';
import {Md5} from 'ts-md5';
import packageInfo from 'package.json';
import {environment} from 'src/environments/environment';
import { JsEncryptWrapperService } from './js-encrypt-wrapper.service';

interface UUID_COOKIE_TYPE {
  username: string;
  UUID: string;
}

@Injectable({
  providedIn: 'root'
})
export class UuidManagerService {

  constructor(
    private deviceService: DeviceDetectorService,
    private jsEncryptService:JsEncryptWrapperService
  ) {
  }

  get getDeviceType(): string | null {
    try {
      return this.deviceService.deviceType === 'desktop' ? this.deviceService.deviceType : this.deviceService.device;
    } catch {
      return null;
    }
  }

  get getBrowser(): string | null {
    try {
      return this.deviceService.browser;
    } catch {
      return null;
    }
  }

  get getApplicationVersion(): string | null {
    try {
      return packageInfo.version;
    } catch {
      return null;
    }
  }

  public async getUUID(username: string): Promise<string | void> {
    return await this.getFingerPrintData().then((res) => {
      const encryptedVariables: UUID_COOKIE_TYPE = {
        UUID: res.visitorId,
        username
      };
      return Md5.hashStr(JSON.stringify(encryptedVariables)) ?? undefined;
    }).catch((err) => {
      if (!environment.production) {
        console.log(err);
      }
      return undefined;
    });
  }

  private async getFingerPrintData(): Promise<GetResult> {
    try {
      const fpPromise = FingerprintJS.load();
      const fp = await fpPromise;
      return await fp.get();
    } catch {
      return await new Promise((resolve, reject) => reject(false));
    }
  }


  public async getLoginDeviceData(): Promise<string | null> {
    return await this.getFingerPrintData()
      .then((res) => {
       const loginDeviceData = {
          deviceType: this.getDeviceType,
          uuid:res.visitorId,
          applicationName: 'Adminweb',
          browserType: this.getBrowser,
          applicationVersion: this.getApplicationVersion,
        }
        return this.jsEncryptService.encryptData(JSON.stringify(loginDeviceData)) ?? null;
      })
      .catch((err) => {
        if (isDevMode()) {
          console.log(err);
        }
        return null;
      });
  }
}