<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mat-elevation-z8 mainCard">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" class="sectionTitle">
    <span>Transaction Fees</span>
    <a mat-raised-button color="primary" pull-right (click)="addTransactionFeesDialog()"> Add </a>
  </mat-card-title>

  <mat-card-content class="sectionContent">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="totalSpend programHead">
      <div fxLayout="column" fxFlex="11.11" class="displayData">
        <label>Activity Type</label>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData">
        <label>Activity</label>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData">
        <label>Status</label>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData">
        <label>Flat Fee or % of Transaction</label>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData">
        <label>Flat Fee / Percent</label>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData">
        <label>Fee Collection Method</label>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData">
        <label>Days to collect fee?</label>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData">
        <label>Enabled / Disabled</label>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData">
        <label>Overrides</label>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around start" class="programTable"
      *ngFor="let s of dataSource | paginate: {totalItems: paginationConfig.TotalItems, itemsPerPage: paginationConfig.ItemsPerPage, currentPage: paginationConfig.CurrentPage}; index as index">
      <div fxLayout="column" fxFlex="11.11" class="displayData programContent">
        <span>{{s.eventMechanism}}</span>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData programContent">
        <span>{{s.eventAction}}</span>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData programContent">
        <span>{{s.status}}</span>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData programContent">
        <span></span>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData programContent">
        <span></span>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData programContent">
        <span>{{s.collectType}}</span>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData programContent">
        <span>{{s.feeForStaus}}</span>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData programContent">
        <span>
          <mat-slide-toggle [disabled]="true"></mat-slide-toggle>
        </span>
      </div>
      <div fxLayout="column" fxFlex="11.11" class="displayData programContent">
        <span>
          <button mat-icon-button class="matBtns" (click)="removeTransactionFeesDialog(s, index)">
            <i class="m-trash-b danger"></i>
          </button>
          <button mat-icon-button class="matBtns" (click)="updateTransactionFeesDialog(s, index)">
            <i class="m-pencil update"></i>
          </button>
        </span>
      </div>
    </div>
    <div class="empty-data" *ngIf="dataSource.length===0">No Data Available</div>
    <pagination-controls class="common-pagination" (pageChange)="pageChanged($event)" autoHide="true">
    </pagination-controls>
  </mat-card-content>
</mat-card>
