/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[DecimalNumberOnly]'
})
export class DecimalNumberOnlyDirective {
    // Allow decimal numbers and negative values
    private regex: RegExp = new RegExp(/^-?\d+(\.\d{0,2})?$/);

    // tslint:disable-next-line: no-input-rename
    @Input('DecimalNumberOnly') DecimalNumberOnly = { allow: true, negative: true };
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.DecimalNumberOnly.allow) {
            let next: string;
            if (this.specialKeys.indexOf(event.key) !== -1) {
                return;
            }
            const current: string = this.el.nativeElement.value;
            if (current !== undefined) { next = current.concat(event.key); }
            const isNegative = next === '-' && this.DecimalNumberOnly.negative ? false : true;
            if (next === '.') {
                this.el.nativeElement.value = '0';
                next = '0';
            }
            if (next && !String(next).match(this.regex) && isNegative) {
                event.preventDefault();
            }
        }
    }
}
