<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<mat-card class="mat-elevation-z8 mainCard">
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" class="sectionTitle">
    <span>Single Transaction Value Controls</span>
    <a mat-raised-button color="primary" pull-right (click)="addSingleTransactionDialog()"
      *ngIf="transaction['policyOptionadd']==='add'"> Add </a>
  </mat-card-title>

  <mat-card-content class="sectionContent">
    <span
      *ngFor="let s of dataSource | paginate: {totalItems: paginationConfig.TotalItems, itemsPerPage: paginationConfig.ItemsPerPage, currentPage: paginationConfig.CurrentPage}; index as index">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h6 class="toleranceTitle">{{merchantType[s.mccType]}}</h6>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" class="">
        <div fxLayout="column" fxFlex="90" class="box-padding">
          <div class="madeInput">
            <label>Max. Amount</label>
            <span *ngIf="s.amount">$ {{s.amount | number:'.2'}}</span>
          </div>
        </div>
        <div fxLayout="column" fxFlex="10" class="box-padding" *ngIf="transaction['policyOptionedit']==='edit'">
          <div fxLayout="row">
            <div class="madeInput">
              <label>Action</label>
              <span>
                <button class="threeDot" mat-flat-button [matMenuTriggerFor]="singlemenu"><i
                    class="m-ellipsis-v"></i></button>
                <mat-menu #singlemenu="matMenu">
                  <a mat-menu-item (click)="removeSingleTransactionDialog(s, index)">Remove</a>
                  <a mat-menu-item (click)="updateSingleTransactionDialog(s, index)">Edit</a>
                </mat-menu>
              </span>
            </div>
          </div>
        </div>
      </div>
    </span>
    <div class="empty-data" *ngIf="dataSource.length===0">No Data Available</div>
    <pagination-controls class="common-pagination" (pageChange)="pageChanged($event)" autoHide="true">
    </pagination-controls>
  </mat-card-content>
</mat-card>
