<div>
    <div class="snack-bar-left">
        <span *ngIf="data.globalCssClass == 'error'" class="snack-bar-span">
            <i class="error-i m-error-ban"> </i>
        </span>
        <span *ngIf="data.globalCssClass == 'success'" class="snack-bar-span">
            <i class="m-success-tick success-i"> </i>
        </span>
    </div>
    <div class="snack-bar-right-div">
        <div class="snack-bar-button">
            <button mat-icon-button type="button" class="cancel-btn" (click)="dismissSnackBar()">
                <mat-icon>close</mat-icon>
              </button>
        </div>
        <div class="snack-bar-right-heading">
            <p *ngIf="data.globalCssClass == 'error'" class="p-snack-bar-error">Error <span *ngIf="data.error_code"> ( {{data.error_code}} )</span></p>
            <p *ngIf="data.globalCssClass == 'success'" class="p-snack-bar-success">Success </p>
        </div>       
        <div class="snack-bar-message">
            {{data.message}}
        </div>
    </div>
</div>
