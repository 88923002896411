/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
export const errorMessage = {
    error400: 'Bad request',
    error403: 'Invalid Token',
    error404: 'Not Found',
    error500: 'Internal server error',
    error504: 'Time Out',
    loginError: 'Invalid username and password',
    error406:'Not Acceptable'
};
