/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonUtility } from 'src/app/utility/common.utility';

@Component({
  selector: 'app-date-range-dialog',
  templateUrl: './date-range-dialog.component.html',
  styleUrls: ['./date-range-dialog.component.scss']
})
export class DateRangeDialogComponent implements OnInit {

  submitted = false;
  dateRangeForm: UntypedFormGroup;
  todayDate = new Date();

  constructor(
    public dialogRef: MatDialogRef<DateRangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef
  ) {
    this.dateRangeForm = this.formBuilder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    if (this.data && !CommonUtility.isEmptyObj(this.data)) {
      this.dateRangeForm.controls.endDate.setValue(this.data.endDate);
      this.dateRangeForm.controls.startDate.setValue(this.data.startDate);
      this.cdRef.detectChanges();
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.dateRangeForm.invalid) {
      return;
    }

    this.dialogRef.close({ startDate: this.dateRangeForm.value.startDate, endDate: this.dateRangeForm.value.endDate });
  }

  cancelClick() {
    this.dialogRef.close(false);
  }
}
