<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->

<div class="loginWrapp flex flex-row-reverse justify-between">
  <div class="mocaLogo" flex-1>
    <img src="assets/images/logo_white.jpg" alt="Moca" title="MOCA" />
  </div>
  <div class="loginInfo flex-1 flex flex-col justify-center">
    <form [formGroup]="setPassForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <h1>Set Password</h1>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>New Password</mat-label>
          <input [type]="hide ? 'text' : 'password'" matInput formControlName="NewPass" autocomplete="off" />
          <mat-icon matSuffix class="eyeball" (click)="hide = !hide">{{!hide ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </mat-form-field>
        <mat-error *ngIf="submitted && setPassForm.hasError('required',['NewPass'])" class="invalid-feedback">
          New Password is required
        </mat-error>
        <mat-error
          *ngIf="submitted && (setPassForm.hasError('pattern',['NewPass']) || setPassForm.hasError('minlength',['NewPass']))"
          class="invalid-feedback">
          Password must be at least 1 Uppercase, 1 Numeric and 1 special character and at least 8
          characters
        </mat-error>
      </div>
      <div class="mandatory flex flex-col">
        <mat-form-field>
          <mat-label>Confirm Password</mat-label>
          <input [type]="hide1 ? 'text' : 'password'" matInput
            formControlName="ConfirmPass" autocomplete="off" />
          <mat-icon matSuffix class="eyeball" (click)="hide1 = !hide1">{{!hide1 ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </mat-form-field>
        <mat-error *ngIf="submitted && setPassForm.hasError('required',['ConfirmPass'])" class="invalid-feedback">
          Confirm Password is required
        </mat-error>
        <mat-error *ngIf="submitted && setPassForm.hasError('misMatch')" class="invalid-feedback">
          Confirm Password do not match
        </mat-error>
      </div>
      <div class="loginBtn flex flex-col">
        <button mat-raised-button color="primary">Set Password</button>
      </div>
    </form>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.3)" size = "medium" color = "#fff" type = "ball-beat" [fullScreen] = "true"><p style="color: white" > Please wait... </p></ngx-spinner>
