/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestServicesService } from 'src/app/services/rest-services.service';
import { CustomSnakbarComponent } from 'src/app/shared/shared-component/custom-snakbar/custom-snakbar.component';

@Component({
  selector: 'app-remove-transaction-fees-dialog',
  templateUrl: './remove-transaction-fees-dialog.component.html',
  styleUrls: ['./remove-transaction-fees-dialog.component.scss']
})
export class RemoveTransactionFeesDialogComponent implements OnInit {
  rowData = {} as any;

  constructor(
    public dialogRef: MatDialogRef<RemoveTransactionFeesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customeSnakbar:CustomSnakbarComponent,
    private spinner: NgxSpinnerService,
    private service: RestServicesService
  ) { }

  ngOnInit() {
    this.rowData = this.data.source;
  }

  onSubmit() {
    this.spinner.show();
    this.service.deleteTransactionFee(this.rowData.id).subscribe((resp: any) => {
      this.spinner.hide();
      if (resp.status === 200) {
        this.customeSnakbar.openCustomSnakbar('Removed successfully', '', 'success');
        this.dialogRef.close();
      }
    });
  }
}
