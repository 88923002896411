/** ---
  Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.

  Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.

  @Author: Newtech Systems Development team
  @Author: Moca Financial Inc Development team

  Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  Violators will be prosecuted.
**/
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { RestServicesService } from 'src/app/services/rest-services.service';

@Component({
  selector: 'app-show-document-dialog',
  templateUrl: './show-document-dialog.component.html',
  styleUrls: ['./show-document-dialog.component.scss']
})
export class ShowDocumentDialogComponent implements OnInit {

  imgUrl = '';
  fileUrl = '';
  title = '';

  blobData: any;
  isNewUpload = false;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = true;
  containWithinAspectRatio = false;
  transform: ImageTransform = { scale: this.scale };
  updatedImgArryBuffer: any;
  format = 'jpeg';


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ShowDocumentDialogComponent>,
    protected service: RestServicesService
  ) {
    if (this.data) {
      this.imgUrl = this.data.imgUrl;
      this.fileUrl = this.data.fileUrl;
      this.blobData = this.data.blob;
      this.isNewUpload = this.data.isNewUpload;
      this.title = this.data.title;
      this.format = this.blobData.type.split('/')[1] === 'png' ? 'png' : this.format;
    }
  }

  ngOnInit() {
    this.imageChangedEvent = this.blobData;
  }
  downloadClick() {
    const a = document.createElement('a');
    a.href = this.fileUrl;
    a.download = 'receipt.png';
    a.click();
  }
  cancelClick() {
    const resp = { confirmed: false };
    this.dialogRef.close(resp);
  }
  uploadImage() {
    const resp: any = { confirmed: true };

    resp.imgArrayBuffer = this.updatedImgArryBuffer;
    this.dialogRef.close(resp);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event.base64;

    const fileName = this.blobData.name ? this.blobData.name : (this.format === 'jpeg' ? 'receipt.jpg' : 'receipt.png');
    this.updatedImgArryBuffer = this.dataURLtoFile(this.croppedImage, fileName);
  }
  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const jpgList = ['jpg', 'jpeg', 'JPEG', 'JPG'];
    mime = 'image/png';
    if (jpgList.includes(filename.split('.')[1])) {
      mime = 'image/jpeg';
    }

    return new File([u8arr], filename, { type: mime });
  }
  b64toBlob(b64Data, contentType = 'image/jpeg', sliceSize = 512, filename) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new File(byteArrays, filename, { type: contentType });
    return blob;
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const bb = new Blob([ab], { type: `${mimeString}` });
    return bb;
  }



  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }
}
