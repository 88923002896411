<!--
  ~ Copyright (C) Newtech Systems  2017-2018  All Rights Reserved.
  ~
  ~ Copyright (C)  Moca Financial Inc 2019-2021  All Rights Reserved.
  ~
  ~  @Author: Newtech Systems Development team
  ~  @Author: Moca Financial Inc Development team
  ~
  ~ Property of Moca Financial Inc. can't be copied, used or distributed without proper permission from Moca Financial Inc.
  ~  Violators will be prosecuted.
  ~
  -->
<div class="remove-box">
  <div class="flex flex-row justify-around" class="removeClass">
    {{message}}
  </div>
  <div class="button-row gap-x-2.5 flex">
    <button mat-raised-button color="primary" (click)="removeClick()">{{okFlag}}</button>
    <button mat-raised-button color="default" (click)="cancelClick()">Cancel</button>
  </div>
</div>
